<template>
  <div id="error" class="container">
    <div class="inner">
      <h1>404 Error.</h1>
      <p>We can't find the page you are looking for, here are some helpful links instead.</p>
      <ul class="links">
        <li>
          <router-link :to="{name: 'home'}" >Home</router-link>
        </li>
        <li>
          <router-link :to="{name: 'about'}" >About</router-link>
        </li>
        <li>
          <router-link :to="{name: 'clinical-excellence'}" >Clinical Excellence</router-link>
        </li>
        <li>
          <router-link :to="{name: 'community'}" >Community</router-link>
        </li>
        <li>
          <router-link :to="{name: 'join-our-team'}" >Join Our Team</router-link>
        </li>
        <li>
          <router-link :to="{name: 'news'}" >News</router-link>
        </li>
        <li>
          <router-link :to="{name: 'contact'}" >Contact</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>

export default {
  data() {
    return {
    }
  },
  computed: {
  }
}
</script>