import Data from './data.json';

export default {
  state: {
    loaded: false,
    breakpoint: '',
    fullURL: process.env.BASE_URL,
    data: Data,
    regionList: null,
    newsCategories: null,
    linkedInUrl: null,
    facebookUrl: null,
    craftLoaded: null,
    craftPageData: null,
    settingsLoaded: null,
    title: '',
    defaultDescription: 'With 44 branches throughout 13 regions across New Zealand & Australia, you can access convenient expert medical imaging in your local area.',
    description: '',
  },
  mutations: {
    setLoaded (state, payload){
      state.loaded = payload
    },
    setCraftLoaded (state, payload){
      state.craftLoaded = payload
    },
    setCraftPageData (state, payload){
      state.craftPageData = payload
    },
    setSettingsLoaded (state, payload){
      state.settingsLoaded = payload
    },
    setBreakpoint (state, payload){
      state.breakpoint = payload
    },
    setRegionList (state, payload){
      state.regionList = payload
    },
    setNewsCategories (state, payload){
      state.newsCategories = payload
    },
    setLinkedInUrl (state, payload){
      state.linkedInUrl = payload
    },
    setFacebookUrl (state, payload){
      state.facebookUrl = payload
    },
    setCSRFTokenName (state, payload){
      state.csrfTokenName = payload
    },
    setCSRFToken (state, payload){
      state.csrfToken = payload
    },
    setTitle (state, payload){
      state.title = payload
    },
    setDescription (state, payload){
      state.description = payload
    },
  },
  actions: {
  }
}