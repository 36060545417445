<template>
  <default v-if="loaded">
    <router-view></router-view>
  </default>
</template>


<script>
  import Default from '~/components/layouts/Default.vue'
  import gql from 'graphql-tag'

  export default {
    components: { Default },
    data(){
      return {
        breakpoint: '',
        title: 'RHCNZ',
      }
    },
    apollo: {
      globalSets: { query: gql` query{
            globalSets {
              ... on seoDefaults_GlobalSet {
                seoDescription
                seoTitle
              }
              ... on footerLinks_GlobalSet {
                linkedIn
                facebook
              }
            } 
            regions: categories(group:"regions"){
              ... on regions_Category {
                id
                title slug regionBlurb
              }
            }
            newsCategories: categories(group:"news") {
              ... on news_Category {
                id
                title
                slug
              }
            }
            articleCategories: entries(section: "newsArticles", orderBy: ""){ id
              ...on newsArticles_default_Entry{
                id, category {
                  id
                }
              }
            }
        }`,fetchPolicy: 'no-cache',
        result ({ data, loading, networkStatus }) {      
            if (data.globalSets !== null){
              // console.log(data)
              this.$store.commit('setLinkedInUrl', data.globalSets[1].linkedIn);
              this.$store.commit('setFacebookUrl', data.globalSets[1].facebook);
              // Full region list (for feature content)
              this.$store.commit('setRegionList', data.regions);
              // console.log(data.regions)
              // Contextual news categories
              let currentCategories = [{"id":0,"title": 'All News', "slug": 'all-news'}]
              data.articleCategories.forEach(function (articleCategoryValue, articleIndex) {
                data.newsCategories.forEach(function (value, index) {
                  if (articleCategoryValue.category[0].id === value.id){
                    currentCategories.indexOf(value) === -1 ? currentCategories.push(value) : false
                  }
                });
              });
              this.$store.commit('setNewsCategories', currentCategories);
              // console.log(currentCategories)
              this.$store.commit('setSettingsLoaded', true);
            } 
            else{
              this.$router.push({ name: 'errorServer' })
            }
          
        },
        skip() {
          return this.skipQuery
        },
      }
    },
    computed: {
      loaded() {
        return this.$store.state.loaded
      },
      computedTitle() {
        if(this.$route.meta.title) {
          if(this.$route.meta.title === 'Home') {
            return 'RHCNZ Medical Imaging Group'
          } else {
            return `${this.title} - ${this.$route.meta.title}`
          }
        }
        else if(this.$store.state.title) {
          if(this.$store.state.title === 'Home') {
            return 'RHCNZ Medical Imaging Group'
          } else {
            return `${this.title} - ${this.$store.state.title}`
          }
        }
        else{
          return this.title
        }
      },
      computedDescription(){
        if (this.$store.state.description == '')
          return this.$store.state.defaultDescription
        else
          return this.$store.state.description
      }
    },
  	methods: {
  		setup() {
  			this.cssVars();
        console.log('%cDesign : https://choir.nz', 'color: #000000; font-weight: bold; font-size: 11px;');
        console.log('%cDevelopment : https://kichin.co', 'color: #000000; font-weight: bold; font-size: 11px;');
  			console.log('–');
        if (!this.$store.state.settingsLoaded){
          this.$apollo.queries.globalSets.skip = false
          this.$apollo.queries.globalSets.refetch()
        }
  			this.breakpointCheck();
        this.$nextTick(() => {
          this.cssVars();
        });
  		},
  		resize(e) {
  			this.breakpointCheck();
  			this.cssVars();
  		},
      breakpointCheck(resize) {
        let w = window.innerWidth;
        let breakpoint = '';

        // check these values against /assets/scss/_variables
        if (w <= 576) {
          breakpoint = 'xs';
        } else if (w > 576 && w <= 767) {
          breakpoint = 'sm';
        } else if (w >= 768 && w <= 991) {
          breakpoint = 'md';
        } else if (w >= 992 && w <= 1199) {
          breakpoint = 'lg';
        } else if (w >= 1200 && w <= 1439) {
          breakpoint = 'xl';
        } else {
          breakpoint = 'xxl';
        }

        if (breakpoint !== this.breakpoint) {
          this.breakpoint = breakpoint
          this.$store.commit('setBreakpoint', breakpoint);
          console.log('Breakpoint : ' + breakpoint)
        }
      },
      cssVars() {
        this.$updateCssVars();
      }
    },
    metaInfo() {
      return {
        title: this.computedTitle,
        meta: [
          {
          name: 'description',
          content: this.computedDescription
          },
          {
          property: 'og:title',
          content: this.computedTitle
          },
          {
          property: 'og:description',
          content: this.computedDescription
          },
        ],
        link: [
            {rel: 'canonical', href: this.$store.state.fullURL + this.$route.fullPath}
          ]
      }
    },
    created() {
      this.setup();
      window.addEventListener("resize", this.resize);
      this.$store.commit('setLoaded', true);
    }
  }
</script>