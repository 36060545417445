<template>
  <div class="feature-carousel">
    <div class="title" v-html="title"></div>
    <carousel-helper :options="carouselOptions" :custom-pagination="false" :custom-nav="true">
      <swiper-slide v-for="(slide, index) in data.slides" class="slide" :key="index">
        <ul>
          <li>{{slide.heading}}</li>
          <li>{{slide.teaser}}</li>
          <li>
            <router-link :to="{name: 'news-article', params: { slug: slide.slug}}" class="btn">READ MORE</router-link>
          </li>
        </ul>
      </swiper-slide>
    </carousel-helper>
  </div>
</template>

<script>
import CarouselHelper from "./helpers/CarouselHelper.vue";
import { SwiperSlide } from 'vue-awesome-swiper'

  export default {
    components: { CarouselHelper, SwiperSlide },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        carouselOptions: {
          centeredSlides: true,
          spaceBetween: 60,
          loop: true,
          speed: 1000,
          autoHeight: true,
          allowTouchMove: false,
          preventClicks: false,
          preventClicksPropagation: false
          // slidesPerView: 'auto',
          // autoplay: 5000,
        }
      }
    },
    computed: {
      title() {
        let title = this.data.title
        let breakpoint = this.$store.state.breakpoint

        if(breakpoint === 'xs' || breakpoint === 'sm') {
          return title.replace(/<br\/>/g, ' ')
        } else {
          return title
        }
      }
    },
    methods: {
    },
  }
</script>