<template>
  <div id="fineprint" v-if="craftLoaded && settingsLoaded">
    
    <hero-module>
      <template v-slot:heading>{{entry.title}}</template>
    </hero-module>

    <smart-container :inset="2">
      <h2 v-if="entry.intro">{{entry.intro}}</h2>
      <div class="wysiwyg" v-html="entry.content"></div>
    </smart-container>
  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import gql from 'graphql-tag'

  export default {
    components : { HeroModule, SmartContainer },
    data() {
      return {
        entry: Object,
      }
    },
    computed: {
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "bookingPrivacyPolicy") {
            id title slug 
            ...on bookingPrivacyPolicy_bookingPrivacyPolicy_Entry {
              seoTitle seoDescription
              intro: termsIntro content: fullWysiwyg
              }
            } 
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              console.log(data)
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
    }
  }
</script>