<template>
  <div id="clinical-excellence-item"  v-if="craftLoaded && settingsLoaded">
    <hero-module :subheading="entry.subheading">
      <template v-slot:heading>{{ entry.heading }}</template>
    </hero-module>

    <smart-container :inset="1" :graident="{ color: 'blue', position: 'bl'}">
      <h2>{{entry.director[0].title}}</h2>
      <div class="director">

        <div class="name">
          <span>{{entry.director[0].name}}</span>{{directorDetail}}
        </div>
        
        <div class="image img-wrapper is-cover three-two">
          <craft-image-helper :src="entry.director[0].image"></craft-image-helper>
        </div>
        
        <div class="wysiwyg" v-html="entry.directorBlurb"></div>
        
        <statement-module :bg="false" :borders="'top'">
          <template v-slot:statement>{{ entry.directorQuote }}</template>
          <template v-if="entry.citationTitle" v-slot:cite>{{ entry.citationTitle }}</template>
        </statement-module>
      </div>
    </smart-container>

     <smart-container :inset="1" v-if="entry.accordion.length">
      <accordion-item v-for="(accordion, index) in entry.accordion" :group-uid="uid" :open="(index === 0)">
        <template slot="title">{{accordion.title}}</template>
        <template slot="byline">{{accordion.byline}}</template>
        <template slot="content">
          <div class="wysiwyg" v-html="accordion.copy"></div>
          <div class="assets-wrapper">
            <div v-if="accordion.image.length" class="img-wrapper is-cover three-two">
              <craft-image-helper :src="accordion.image"></craft-image-helper>
            </div>
            <video-helper v-if="accordion.videoURL !== null" :src="accordion.videoURL"></video-helper>
          </div>
          <statement-module v-if="accordion.statement !== '' && accordion.statement !== null">
            <template v-slot:statement>{{accordion.statement}}</template>
            <template v-slot:cite>{{accordion.citation}}</template>
          </statement-module>
        </template>
      </accordion-item>
    </smart-container>

    <div v-if="(entry.articleContentVisibility && xsMode && entry.image.length)" class="fluid-container img-wrapper is-cover one-two">
      <craft-image-helper :src="entry.image"></craft-image-helper>
    </div>

    <smart-container v-if="(entry.articleContentVisibility && (entry.clinicalExcellenceHeading || entry.intro || entry.content || entry.image.length))" :inset="1" :graident="{ color: 'purple', position: 'br'}">
      <div class="content-wrapper">
        <div v-if="(!xsMode && entry.image.length)" class="img-wrapper is-cover one-two">
          <craft-image-helper :src="entry.image"></craft-image-helper>
        </div>
        <div v-if="(entry.clinicalExcellenceHeading || entry.intro || entry.content)" :class="{'inset': !xsMode}">
          <h2 v-if="entry.clinicalExcellenceHeading">{{entry.clinicalExcellenceHeading}}</h2>
          <p v-if="entry.author" class="small author">{{entry.author}}</p>
          <p v-if="entry.intro" class="big">{{entry.intro}}</p>
          <div v-if="entry.content" class="wysiwyg" v-html="entry.content"></div>
        </div>
      </div>
    </smart-container>

    <smart-container :inset="1" v-if="entry.featureCarousel">
      <teaser-carousel :data="teaserCarousel"></teaser-carousel>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import TeaserCarousel from '../TeaserCarousel.vue'
import StatementModule from '../StatementModule.vue'
import AccordionItem from '../helpers/AccordionItem.vue'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, SmartContainer, TeaserCarousel, StatementModule, AccordionItem },
  data() {
    return {
      entry: Object,
    }
  },
  computed: {
    directorDetail() {
      let result = []
      if(this.entry.director[0].qual) {
        result.push(this.entry.director[0].qual)
      }
      if(this.entry.director[0].qual && this.entry.director[0].specialty) {
        result.push(' / ')
      }
      if (this.entry.director[0].specialty) {
        result.push(this.entry.director[0].specialty)
      }
      return result.join(' ')
    },
    teaserCarousel(){
      if (this.entry.carouselMode === 'news'){
        let teaserCarousel = {mode: 'news'}
        teaserCarousel.mode = 'news'
        teaserCarousel.slides = this.entry.newsArticles
        return teaserCarousel
      }
      else{
        let teaserCarousel =  {mode: 'jobs'}
        teaserCarousel.slides = this.entry.jobListings
        return teaserCarousel
      }
    },
    xsMode() {
      return this.$store.state.breakpoint === 'xs' || this.$store.state.breakpoint === 'sm'
    }
  },
  apollo: {
      page: {
        query: gql` query variables($slug: [String!]){
          page: entry(section: "excellenceArticles",slug: $slug) {
            id title slug postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
            ...on excellenceArticles_default_Entry {
              seoTitle seoDescription
              heading subheading author: articleAuthor articleContentVisibility
              director{
                ...on clinicalAdvisoryGroup_default_Entry{
                  name: profileName qual: profileQualification specialty: profileSpecialty title: profileTitle
                  image: profileImage{
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                  }
                }
              }
              directorBlurb directorQuote citationTitle
              image {
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              accordion{
                ...on accordion_detail_BlockType{
                  title: accordionTitle
                  byline: accordionByline
                  copy: accordionCopy
                  image: accordionImage{
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                  videoURL
                  statement: accordionStatement
                  citation: accordionCitation
                }
              }
              clinicalExcellenceHeading intro: clinicalExcellenceBlurb content: fullWysiwyg 
              featureCarousel
              carouselMode
              newsArticles{
                id slug 
                ...on newsArticles_default_Entry {
                  heading: title teaser category { id title }
                    image: heroImage {
                      url
                      title
                      width
                      height
                      focalPoint
                      mimeType
                      s400: url @transform(width: 400)
                      s800: url @transform(width: 800)
                      s1200: url @transform(width: 1200)
                      s1600: url @transform(width: 1600)
                      s2000: url @transform(width: 2000)
                      s2400: url @transform(width: 2400)
                      s2800: url @transform(width: 2800)
                      s3200: url @transform(width: 3200)
                    }
                }                  
              }
              jobListings{
                id slug
                ...on jobVacancies_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
                ...on jobVacanciesCMS_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
              }
            }
          }     
        }
        `,
        variables() {      
          return {slug: this.$route.params.slug}
        },
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              console.log(data.page)
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
  methods: {
  },
}
</script>