<template>
  <div id="contact"  v-if="craftLoaded && settingsLoaded">

    <hero-module :graident="{ anim: true, color: 'blue', angle: 15 }" :subheading="entry.subheading">
      <template v-slot:heading>{{ entry.heading }}</template>
    </hero-module>

    <smart-container :inset="1">
      <div class="corporate">
        <h3>{{entry.corporateHeading}}</h3>
        <p>{{entry.corporateBlurb}}</p>
        <div class="staff-wrapper">
          <div class="staff-inner">
            <div class="staff-member" v-for="staff in executiveTeam">
              <p><span>{{staff.name}}</span>{{staff.title}}</p>
              <a v-if="staff.email" :href="`mailto:${staff.email}`">Email {{$getFirstName(staff.name)}}</a>
            </div>
          </div>
        </div>
      </div>
    </smart-container>

    <smart-container :inset="1">
      <div class="services">
        <h3>{{entry.servicesHeading}}</h3>
        <p>{{entry.servicesBlurb}}</p>
        <div class="brands-wrapper">
          <div class="brands-inner">
            <ul class="brand">
              <li>
                <img :src="$getImageSrc('auckland-radiology-logo.svg')" alt="Auckland Radiology Logo"></img>
              </li>
              <li>{{entry.aucklandRadiologyBlurb}}</li>
              <li><a href="https://arg.co.nz" target="_blank">Visit Auckland Radiology</a></li>
            </ul>
            <ul class="brand">
              <li>
                <img :src="$getImageSrc('bay-radiology-logo.svg')" alt="Bay Radiology Logo"></img>
              </li>
              <li>{{entry.bayRadiologyBlurb}}</li>
              <li><a href="https://bayradiology.co.nz" target="_blank">Visit Bay Radiology</a></li>
            </ul>
            <ul class="brand">
              <li>
                <img :src="$getImageSrc('pacific-radiology-logo.svg')" alt="Pacific Radiology Logo"></img>
              </li>
              <li>{{entry.pacificRadiologyBlurb}}</li>
              <li><a href="https://pacificradiology.com" target="_blank">Visit Pacific Radiology</a></li>
            </ul>
          </div>
        </div>
      </div>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, SmartContainer },
  data() {
    return {
      entry: Object,
      executiveTeam: [],
    }
  },
  computed: {
  },
  apollo: {
      page: {
        query: gql`query{
          page: entry(section: "contact") {
            id title slug 
            ...on contact_contact_Entry {
              seoTitle seoDescription
              heading subheading corporateHeading corporateBlurb servicesHeading servicesBlurb aucklandRadiologyBlurb bayRadiologyBlurb pacificRadiologyBlurb
              }
            } 
            executiveTeam: entries(section: "executiveTeam"){
              id slug
              ...on executiveTeam_default_Entry{
                name: profileName title: profileTitle email: profileEmail
              }
            }
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              console.log(data)
              this.executiveTeam = data.executiveTeam
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
  methods: {
  },
}
</script>