<template>
  <div id="news" v-if="settingsLoaded && craftLoaded">
    <hero-module :graident="{ anim: true, color: 'green', angle: 15 }" :subheading="entry.subheading">
      <template v-slot:heading>{{ entry.heading }}</template>
    </hero-module>

    <smart-container :inset="1" :bg="'vl-grey'" v-if="teaserCarousel.slides.length > 0">
      <teaser-carousel :data="teaserCarousel" class="no-padding"></teaser-carousel>
    </smart-container>

    <smart-container :inset="1" :graident="{color: 'purple', position: 'br', start: 70}">
      <div class="articles">
        <ul class="article-filters">
          <li v-for="filter in this.$store.state.newsCategories" @click="setFitler(filter)" :key="filter.id" :slug="filter.slug" :class="{'active': filter.slug === activeFilter.slug}">{{filter.title}}</li>
        </ul>
        <div class="article-grid">
          <router-link 
            class="article-card" 
            v-for="article in articles" 
            :to="{name: 'news-article', params: {slug: article.slug}}"
            :class="{ 'no-image': !article.heroImage.length }" :key="article.id">
            <ul>
              <li class="overlay">
                <span class="btn btn-white">Read more</span>
              </li>
              <li v-if="article.heroImage.length" class="img-wrapper is-cover three-two">
                <craft-image-helper :src="article.heroImage"></craft-image-helper>
              </li>
              <li class="category">{{article.category[0].title}}</li>
              <li class="heading">{{article.title}}</li>
              <li class="teaser" v-if="!article.image">
                <div>{{article.teaser}}</div>
              </li>
            </ul>
          </router-link>
          <div v-if="loadedArticles < totalArticles" class="load-more btn" :class="{disabled: loading}" @click="loadMore()">{{loadingStatus}}</div>
        </div>
      </div>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import TeaserCarousel from '../TeaserCarousel.vue'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, SmartContainer, TeaserCarousel },
  data() {
    return {
      entry: Object,
      articles: Array,
      articlesLoaded: false,
      total: 0,
      batchSize: 12,
      loading: false,
      skipQuery: true,
      activeFilter: {title: "All News", slug: "all-news", id:0},
      showLoadMore: true,
      loading: false,
      skipQuery: false,
      teaserCarousel: {
        mode: 'news', // news | jobs
        slides: []
      },
    }
  },
  computed: {
    loadingStatus() {
      return (this.loading) ? 'Loading...' : 'Load more'
    },
    loadedArticles(){
      return this.articles.length
    },
    totalArticles(){
      return this.total
    },
  },
  apollo: {
      newsArticles: {
        query: gql`query variables(
                $offset: Int!, 
                $limit: Int!, 
                $categoryId: [QueryArgument], 
                ){ 
                    entry(section: "news") {
                        id title slug 
                        ...on news_news_Entry {
                          seoTitle seoDescription
                          heading subheading 
                          featuredArticle{
                            ...on newsArticles_default_Entry{
                              id title heading teaser
                              category{
                                title
                              }
                              slug
                              image: heroImage{
                                url
                                          title
                                          width
                                          height
                                          focalPoint
                                          mimeType
                                          s400: url @transform(width: 400)
                                          s800: url @transform(width: 800)
                                          s1200: url @transform(width: 1200)
                                          s1600: url @transform(width: 1600)
                                          s2000: url @transform(width: 2000)
                                          s2400: url @transform(width: 2400)
                                          s2800: url @transform(width: 2800)
                                          s3200: url @transform(width: 3200)
                              }
                            }
                          }
                        }
                    } 
                    newsArticles: entries(section: "newsArticles", 
                            orderBy: "postDate DESC",
                            offset: $offset,
                            limit: $limit,
                            relatedTo: $categoryId)
                      {
                        ...on newsArticles_default_Entry{
                         id title slug date: postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
                           category: category {
                              title
                            }
                            teaser
                            heroImage {
                              url
                              title
                              width
                              height
                              focalPoint
                              mimeType
                              s400: url @transform(width: 400)
                              s800: url @transform(width: 800)
                              s1200: url @transform(width: 1200)
                              s1600: url @transform(width: 1600)
                              s2000: url @transform(width: 2000)
                              s2400: url @transform(width: 2400)
                              s2800: url @transform(width: 2800)
                              s3200: url @transform(width: 3200)
                            }
                          }
                    }
                    total: entryCount(section: "newsArticles",relatedTo: $categoryId )
                  }`,
        fetchPolicy: 'no-cache',
        variables(){
          let offset = 0
          let limit = 0
          let categoriesVal = []
          if (this.activeFilter.id !== 0)
            categoriesVal.push(this.activeFilter.id)
          else
            categoriesVal = []

          if (this.articlesLoaded){
            offset = this.articles.length
            limit = this.batchSize
          }
          else{
            offset = 0
            limit = this.batchSize
          }
          return {
            offset: offset,
            limit: limit,
            categoryId : categoriesVal,
          }
        },
        result ({ data, loading, networkStatus }) {
          if(data.newsArticles !== null) {
            if (!this.articlesLoaded){
              // console.log('refresh article set')
              // console.log(data)
              this.entry = data.entry
              if (data.entry.seoTitle !== null)
                this.$store.commit('setTitle',data.entry.seoTitle)
              else
                this.$store.commit('setTitle',data.entry.title)
              if (data.entry.seoDescription !== null)
                this.$store.commit('setDescription',data.entry.seoDescription)
              else
                this.$store.commit('setDescription','')
              // supplement component level data with CMS data
              let teaserCarousel = this.teaserCarousel
              data.entry.featuredArticle.forEach(function (article, index) {
                teaserCarousel.slides.push(article)
              });
              this.articles = data.newsArticles
              this.articlesLoaded = true
              this.total = data.total
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
              this.$apollo.queries.newsArticles.skip = true
            }
            else{
              // console.log('extend article set')
              // console.log(data)
              this.articles.push(...data.newsArticles)
              this.$apollo.queries.newsArticles.skip = true
            }
            this.$apollo.queries.newsArticles.skip = true
          }
        },
        skip() {
          return this.skipQuery
        }
      }
    },
  methods: {
    setFitler(filter) {
      this.activeFilter = filter
      this.articlesLoaded = false
      this.newsArticles = []
      this.total = 0
      this.$apollo.queries.newsArticles.skip = false
      this.$apollo.queries.newsArticles.refetch()
    },
    loadMore() {
      this.loading = true
      this.$apollo.queries.newsArticles.skip = false
      this.$apollo.queries.newsArticles.refetch()
    },
    checkActiveFilter(filter){
      if (filter.slug === this.activeFilter.slug)
        return true 
      else
        return false
    }
  },
  created(){
      this.$apollo.queries.newsArticles.skip = false
      this.$apollo.queries.newsArticles.refetch()
    }
}
</script>