<template>
  <smart-container id="hero" :class="classes" :inset="1" :graident="graident">
    <router-link v-if="showLink" class="back" :to="{name: linkName}">{{linkTitle}}</router-link>
    <h1>
      <slot name="heading"></slot>
    </h1>
    <div class="subheading" v-if="subheading" v-html="subheading"></div>
    <share-helper v-if="showSocial" :title="socialTitle">Share this {{shareTitle}}</share-helper>
  </smart-container>
</template>

<script>
import SmartContainer from './SmartContainer.vue'
import ShareHelper from './helpers/ShareHelper.vue'

  export default {
    components : { SmartContainer, ShareHelper },
    props: {
      graident: {
        type: [Object, Boolean],
        default: false,
      },
      subheading: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        linkName: null,
        linkTitle: null,
        shareTitle: null,
      }
    },
    computed: {
      routeName() {
        return this.$route.name
      },
      isClinicalExcellenceItem() {
        if(this.routeName === 'clinical-excellence-item') {
          this.linkTitle = 'Back to clinical excellence'
          this.linkName = 'clinical-excellence'
          return true
        } 
      },
      isJobListing() {
        if(this.routeName === 'job-listing') {
          this.linkTitle = 'Back to job listings'
          this.linkName = 'join-our-team'
          this.shareTitle = 'listing'
          return true
        }
      },
      isNewsArticle() {
        if(this.routeName === 'news-article') {
          this.linkTitle = 'Back to news'
          this.linkName = 'news'
          this.shareTitle = 'article'
          return true
        }
      },
      showLink() {
        return this.isClinicalExcellenceItem || this.isJobListing || this.isNewsArticle
      },
      showSocial() {
        return this.isJobListing || this.isNewsArticle
      },
      classes() {
        let result = []
        if(this.showSocial) {
          result.push('is-news')
          result.push('is-listing')
        }
        return result
      },
      hasSubheading() {
        return this.$slots.subheading
      },
      socialTitle() {
        if (this.$slots.heading[0].text) {
          return this.$slots.heading[0].text
        }
      }
    },
    methods: {
    },
  }
</script>