<template>
  <div id="join-our-team" v-if="settingsLoaded && craftLoaded">

    <hero-module :graident="{ anim: true, color: 'purple', angle: 48 }" :subheading="entry.subheading">
      <template v-slot:heading>{{ entry.heading }}</template>
    </hero-module>


    <smart-container :inset="1" :graident="{color: 'blue', angle: 24}">
      <div class="featured-listings">
        <div class="intro">
          <div class="wysiwyg" v-html="entry.intro"></div>
          <div class="btn" @click="scrollToJobs()">View our vacancies</div>
        </div>
        <carousel-helper :options="carouselOptions" :custom-pagination="false" :custom-nav="true">
          <swiper-slide v-for="(job, index) in featured" class="slide" :key="index">
            <ul>
              <li>JOB LISTING</li>
              <li>{{job.title}}</li>
              <li>{{job.location[0].title}}</li>
              <li>
                <router-link :to="{name: 'job-listing', params: { slug: job.slug}}" class="btn">VIEW LISTING</router-link>
              </li>
            </ul>
          </swiper-slide>
        </carousel-helper>
      </div>
    </smart-container>


    <smart-container v-if="entry.featureCarousel" :inset="1" :bg="'vl-grey'">
      <teaser-carousel v-if="entry.featureCarousel" :data="teaserCarousel" class="white-bg"></teaser-carousel>
    </smart-container>


    <smart-container v-if="entry.showImageVideoBlock" :inset="1" :bg="'vl-grey'">
      <image-video-block v-if="entry.showImageVideoBlock" :data="imageVideoData" class="inset"></image-video-block>
    </smart-container>


    <smart-container id="listings" :inset="1" :graident="{color: 'blue', position: 'tl'}">
      <div class="listings">

        <h3>RHCNZ VACANCIES</h3>

        <div class="filters" style="margin-bottom:auto;">
          <p>Our list here shows locations with current vacancies available. Please use the filter to refine the list further.</p>

          <div class="accordion">
            <accordion-item v-for="filter in filters" :group-uid="uid" :single-mode="true">
              <template slot="title">{{$toTitle(filter.name)}} 
                <template v-if="filter.activeFilters.length">({{filter.activeFilters.length}})</template>
              </template>
              <template slot="content">
                <ul>
                  <li v-for="filterItem in getFilterItems(filter)" :class="{'active': filter.activeFilters.includes(filterItem)}"
                    @click="updateFilter(filter, filterItem)">
                    {{filterItemName(filter,filterItem)}}
                  </li>
                </ul>
              </template>
            </accordion-item>
          </div>

          <div class="btn" v-if="hasFilters" @click="clearFilters()">{{clearMessage}}</div>
        </div>
        
        <div class="listings-grid">
          <router-link v-for="listing in filteredListings" :to="{ name: 'job-listing', params: { slug: listing.slug }}">
            <ul>
              <li>{{listing.title}}</li>
              <li>{{shortDescription(listing)}}</li>
              <li>LOCATION: {{listing.location[0].title}}</li>
              <li>LISTED: {{listing.listed}}</li>
              <li>APPLY BY: {{applyByDate(listing)}}</li>
            </ul>
          </router-link>
          <p v-if="!filteredListings.length">There are currently no positions available however please check back as we regulary update this list.</p>
        </div>
      </div>
    </smart-container>

    <smart-container :inset="1">
      <div class="brands inset-2">
        <div class="inner">
          <div class="intro">{{ entry.subsidiaryBlurb }}</div>
          <ul>
            <li>
              <a :href="entry.arCareersLink" target="_blank">
                <img :src="$getImageSrc('auckland-radiology-logo.svg')" alt="Auckland Radiology Logo">
              </a>
            </li>
            <li>
              <a :href="entry.brCareersLink" target="_blank">
                <img :src="$getImageSrc('bay-radiology-logo.svg')" alt="Bay Radiology Logo">
              </a>
            </li>
            <li>
              <a :href="entry.prgCareersLink" target="_blank">
                <img :src="$getImageSrc('pacific-radiology-logo.svg')" alt="Pacific Radiology Logo">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import CarouselHelper from "../helpers/CarouselHelper.vue";
import TeaserCarousel from "../TeaserCarousel.vue";
import { SwiperSlide } from 'vue-awesome-swiper'
import AccordionItem from '../helpers/AccordionItem.vue'
import ImageVideoBlock from "../ImageVideoBlock.vue";
import { EventBus } from '../../event-bus.js'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, SmartContainer, CarouselHelper, TeaserCarousel, SwiperSlide, AccordionItem, ImageVideoBlock },
  data() {
    return {
      entry: Object,
      jobListings: [],
      carouselOptions: {
        spaceBetween: 10,
        loop: true,
        speed: 1000,
        slidesPerView: 2,
        slidesPerGroup: 2,
        allowTouchMove: false,
        preventClicks: false,
        preventClicksPropagation: false
      },
      uid: Date.now() + Math.random(),
      filters: [
        { name: 'location', activeFilters: [] },
        { name: 'speciality', activeFilters: [] },
        { name: 'type', activeFilters: [] },
        { name: 'ourBrands', activeFilters: [] },
      ],
    }
  },
  apollo: {
      jobs: {
        query: gql`query{
          entry(section:"joinOurTeam"){
            id title
            ...on joinOurTeam_joinOurTeam_Entry{
              seoTitle seoDescription
              heading subheading intro heading subheading intro subsidiaryBlurb prgCareersLink arCareersLink brCareersLink
              featureCarousel
              showImageVideoBlock
              imageVideoBlockMode 
              image {
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              videoURL imageVideoBlockContent
              carouselMode
              newsArticles{
                id slug
                ...on newsArticles_default_Entry {
                  heading: title teaser
                    image: heroImage {
                      url
                      title
                      width
                      height
                      focalPoint
                      mimeType
                      s400: url @transform(width: 400)
                      s800: url @transform(width: 800)
                      s1200: url @transform(width: 1200)
                      s1600: url @transform(width: 1600)
                      s2000: url @transform(width: 2000)
                      s2400: url @transform(width: 2400)
                      s2800: url @transform(width: 2800)
                      s3200: url @transform(width: 3200)
                    }
                }                  
              }
              jobListings{
                id slug
                ...on jobVacancies_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
                ...on jobVacanciesCMS_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
              }
            }
          }
          jobs: entries(section: ["jobVacancies","jobVacanciesCMS"], 
            orderBy: "postDate DESC"){ 
              slug
            ...on jobVacancies_default_Entry{
              title
              listed: postDate @formatDateTime (format: "d F Y", timezone: "Pacific/Auckland")
              jobId
              location: jobRegion{
                id title
              }
              speciality: jobCategory{
                id title
              }
              type: jobType{
                id title
              }
              ourBrands: subsidiary{
                id title
              }
              featured{
                id title
              }
              applyBy
              summary
              registrationLink
            }
            ...on jobVacanciesCMS_default_Entry{
              title
              listed: postDate @formatDateTime (format: "d F Y", timezone: "Pacific/Auckland")
              location: jobRegion{
                id title
              }
              speciality: jobCategory{
                id title
              }
              type: jobType{
                id title
              }
              ourBrands: subsidiary{
                id title
              }
              featured{
                id title
              }
              applyByDate @formatDateTime (format: "d F Y", timezone: "Pacific/Auckland")
              shortDescription
              summary
            }
        }   
        
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.entry !== null){
              this.entry = data.entry
              if (data.entry.seoTitle !== null)
                this.$store.commit('setTitle',data.entry.seoTitle)
              else
                this.$store.commit('setTitle',data.entry.title)
              if (data.entry.seoDescription !== null)
                this.$store.commit('setDescription',data.entry.seoDescription)
              else
                this.$store.commit('setDescription','')
              // console.log('Total jobListings potential : ' + data.jobs.length)
              // console.log('pre tidy')
              // console.log(data.jobs)

              let fullJobList = data.jobs

              let invalidSet = []
              fullJobList.forEach(function (listing, index) {
                if (listing.registrationLink !== ''){
                  if (listing.applyBy === '' || listing.applyBy === null){
                    console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing apply by date')
                    invalidSet.push(listing.jobId)
                  }
                  else{
                    var customCleaned = listing.applyBy.replace(/[\[\]']+/g,'')
                    var customArray = customCleaned.split('",');
                    // console.log(listing.jobId + ' : custom Array length: ' + customArray.length)
                    // if (typeof listing.specialty !== 'undefined')
                    //   console.log(listing.jobId + ' : specialty Array length: ' + listing.specialty.length)

                    if (customArray.length < 3){
                      console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing custom field data')
                      // incomplete Job Adder data - omit from result set
                      //data.jobs.splice(index, 1);
                      invalidSet.push(listing.jobId)
                    }
                  }
                  if (typeof listing.speciality === 'undefined'){
                    if (invalidSet.indexOf(listing.jobId) == -1){
                      invalidSet.push(listing.jobId)
                      console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing speciality')
                    }
                  }
                  else{
                    if (listing.speciality.length == 0){
                      if (invalidSet.indexOf(listing.jobId) == -1){
                        console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing speciality')
                        invalidSet.push(listing.jobId)
                      }
                    }
                  }

                  
                  if (typeof listing.featured === 'undefined'){
                    if (invalidSet.indexOf(listing.jobId) == -1){
                      invalidSet.push(listing.jobId)
                      console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing featured')
                    }
                  }
                  else{
                    if (listing.featured.length == 0){
                      if (invalidSet.indexOf(listing.jobId) === -1){
                        console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing featured')
                        invalidSet.push(listing.jobId)
                      }
                    }
                  }

                  if (typeof listing.ourBrands === 'undefined'){
                    if (invalidSet.indexOf(listing.jobId) == -1){
                      invalidSet.push(listing.jobId)
                      console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing subsidiary')
                    }
                  }
                  else{
                    if (listing.ourBrands.length == 0){
                      if (invalidSet.indexOf(listing.jobId) === -1){
                        console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing subsidiary')
                        invalidSet.push(listing.jobId)
                      }
                    }
                  }

                  if (typeof listing.location === 'undefined'){
                    if (invalidSet.indexOf(listing.jobId) == -1){
                      invalidSet.push(listing.jobId)
                      console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing location')
                    }
                  }
                  else{
                    if (listing.location.length === 0){
                      if (invalidSet.indexOf(listing.jobId) === -1){
                        console.log('remove listing (' + listing.jobId +') ' + listing.title + ' due to missing location')
                        invalidSet.push(listing.jobId)
                      }
                    }
                  }
                }
              });

              let tidyJobList = []
            
              // console.log('invalid set')
              // console.log(invalidSet)

              fullJobList.forEach(function(listing, listingIndex){
                // console.log('jobid'+listing.jobId)
                if (invalidSet.indexOf(listing.jobId) === -1){
                  // console.log('all good - add')
                  tidyJobList.push(listing)
                }
              });


              this.jobListings = tidyJobList
              // console.log(this.jobListings)
              // console.log(this.entry)

              this.$store.commit('setCraftPageData', data.entry)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
  computed: {
    featured() {
      // array of featured listings
      let featuredListings = []
      //console.log('featured set load')
      this.jobListings.forEach(function (listing, index) {
        if (listing.featured[0].title === 'Yes'){
          featuredListings.push(listing)
        }
      });
      return featuredListings
    },
    teaserCarousel(){
      if (this.entry.carouselMode === 'news'){
        let teaserCarousel = {mode: 'news'}
        teaserCarousel.mode = 'news'
        teaserCarousel.slides = this.entry.newsArticles
        return teaserCarousel
      }
      else{
        let teaserCarousel =  {mode: 'jobs'}
        teaserCarousel.slides = this.entry.jobListings
        return teaserCarousel
      }
    },
    imageVideoData() {
      let result = {}
      if (this.entry.showImageVideoBlock) {
        result.mode = this.entry.imageVideoBlockMode
        result.copy = this.entry.imageVideoBlockContent
        if (result.mode === 'image') {
          result.src = this.entry.image
        } else {
          result.src = this.entry.videoURL
        }
        return result
      } else {
        return false
      }
    },
    hasFilters() {
      return this.filters.some(filter => filter.activeFilters.length > 0)
    },
    filterCount() {
      return this.filters.reduce((acc, filter) => acc + filter.activeFilters.length, 0)
    },
    clearMessage() {
      let count = this.filterCount
      if(count) {
        if(count > 1) {
          return `Clear ${count} filters`
        } else {
          return `Clear ${count} filter`
        }
      }
    },
    filteredListings() {
      // if no filters are active, return all listings
      if(!this.hasFilters) {
        return this.jobListings
      }
      // if filters are active, return only listings that match any active filters
      return this.jobListings.filter(listing => {
        return this.filters.some(filter => {
          return filter.activeFilters.some(activeFilter => {
            if (listing[filter.name].length>1){
              return (listing[filter.name][0].title === activeFilter || listing[filter.name][1].title === activeFilter)
            }
            else
              return listing[filter.name][0].title === activeFilter
          })
        })
      })
      
    }
  },
  methods: {
    filterItemName(filter,filterItem){
      // transpose for subsidiary
      if (filter.name === 'ourBrands'){
        switch(filterItem){
          case 'PRG':
            return 'Pacific Radiology'
          break;
          case 'BR':
            return 'Bay Radiology'
          break;
          case 'AR':
            return 'Auckland Radiology'
          break;
        }
      }
      else
        return filterItem
      
    },
    applyByDate(listing){
      if (listing.registrationLink){
        // JobAdder - must split custom fields
        var customCleaned = listing.applyBy.replace(/[\[\]']+/g,'')
        var customArray = customCleaned.split('",');
        var applyByDate = customArray[0]
        var cleanApplyByDate = applyByDate.replace(/['"]+/g, '')
        return cleanApplyByDate
      }
      else
        return listing.applyByDate
    },
    shortDescription(listing){
      if (listing.registrationLink){
        var customCleaned = listing.applyBy.replace(/[\[\]']+/g,'')
        var customArray = customCleaned.split('",');
        if (customArray.length>1){
          var shortDescription = customArray[2]
          var cleanShortDescription = shortDescription.replace(/['"]+/g, '')
          return cleanShortDescription
        }
        else
          return ''
      }
      else
        return listing.shortDescription
    },
    scrollToJobs() {
      this.$scrollTo('#listings')
    },
    getFilterItems(filter) {
      // return listings for a given filter
      //let filterList = [...new Set(this.jobListings.map(listing => listing[filter.name][0].title))]
      let filterList = []
      this.jobListings.forEach(function (listing, index) {
        listing[filter.name].forEach(function (listingFilter, key){
          if (!filterList.includes(listingFilter.title))
            filterList.push(listingFilter.title)
        });
      });
      // RHCNZ included but not needed - easiest to drop here
      if (filter.name === 'ourBrands'){
        const index = filterList.indexOf('RHCNZ');
        if (index > -1) { 
          filterList.splice(index, 1);
        }
        return filterList
      }
      else
        return filterList
    },
    updateFilter(filter, value) {
      // get filter with name matching filter.name
      const filterToUpdate = this.filters.find(f => f.name === filter.name)
      // add value to array if not already present, otherwise remove
      filterToUpdate.activeFilters.includes(value) ? filterToUpdate.activeFilters.splice(filterToUpdate.activeFilters.indexOf(value), 1) : filterToUpdate.activeFilters.push(value)
    },
    clearFilters() {
      // reset filter arrays
      this.filters.forEach(filter => filter.activeFilters = [])
      // reset accordion
      EventBus.$emit('toggleAll', { uid: this.uid, status: false});
    }
  },
}
</script>