<template>
  <header v-scroll-lock="overlay">
    <div class="container">
      <router-link :to="{name: 'home'}" :class="{'disabled' : isHome}" class="logo">
        <img :src="$getImageSrc('RHCNZ-logo-color.svg')">
      </router-link>
      <nav :class="navClasses">
        <ul class="menu-items">
          <li v-if="hamburgerMode">
            <router-link :to="{name: 'home'}" @click.native="closeOverlay">Home</router-link>
          </li>
          <li>
            <router-link :to="{name: 'about'}" @click.native="closeOverlay">About</router-link>
          </li>
          <li>
            <router-link :to="{name: 'clinical-excellence'}" @click.native="closeOverlay">Clinical Excellence</router-link>
          </li>
          <li>
            <router-link :to="{name: 'community'}" @click.native="closeOverlay">Community</router-link>
          </li>
          <li>
            <router-link :to="{name: 'join-our-team'}" @click.native="closeOverlay">Join Our Team</router-link>
          </li>
          <li>
            <router-link :to="{name: 'news'}" @click.native="closeOverlay">News</router-link>
          </li>
          <li>
            <router-link :to="{name: 'contact'}" @click.native="closeOverlay">Contact</router-link>
          </li>
        </ul>
        <ul class="icon-bar">
          <li>
            <a href="https://arg.co.nz" target="_blank">
              <img :src="$getImageSrc('auckland-radiology-icon-color.svg')" alt="Auckland Radiology Icon">
            </a>
          </li>
          <li>
            <a href="https://bayradiology.co.nz" target="_blank">
              <img :src="$getImageSrc('bay-radiology-icon-color.svg')" alt="Bay Radiology Icon">
            </a>
          </li>
          <li>
            <a href="https://pacificradiology.com" target="_blank">
              <img :src="$getImageSrc('pacific-radiology-icon-color.svg')" alt="Pacific Radiology Icon">
            </a>
          </li>
        </ul>
      </nav>
      <div v-if="hamburgerMode" @click="toggleOverlay" class="hamburger" :class="{'active' : overlay}"></div>
    </div>
  </header>
</template>


<script>

  export default {
    data(){
      return {
        overlay: false,
        transition: false
      }
    },
    computed: {
      breakpoint() {
        return this.$store.state.breakpoint
      },
      isHome() {
        return this.$route.name === 'home'
      },
      showNav() {
        return !this.hamburgerMode || this.overlay
      },
      hamburgerMode() {
        return this.breakpoint === 'xs' || this.breakpoint === 'sm' || this.breakpoint === 'md'
      },
      navClasses() {
        let result = []
        if(this.showNav) { 
          result.push('active') 
        }
        if(this.transition) { 
          result.push('transition') 
        }
        if(this.hamburgerMode) { 
          result.push('hamburger-mode') 
        }
        return result
      },
    },
    methods: {
      toggleOverlay() {
        this.$updateCssVars()
        this.overlay = !this.overlay
        this.transition = true
        setTimeout(function() {
          this.transition = false
        }, 350);
      },
      closeOverlay() {
        this.overlay = false
        this.transition = true
        setTimeout(() => {
          this.transition = false
        }, 350);
      }
    },
    watch: {
      $route() {
        this.transition = false
        this.overlay = false
      },
      breakpoint() {
        this.transition = false
        this.overlay = false
      }
    },
    mounted() {
      window.addEventListener('keydown', (e) => {
        if (e.key == 'Escape' && this.overlay) {
          this.closeOverlay()
        }
      });
    }
  }
</script>