<template>
  <div id="clinical-excellence"  v-if="craftLoaded && settingsLoaded">
    <hero-module :graident="{anim: true, color: 'blue', angle: 35 }" :subheading="entry.subheading">
      <template v-slot:heading>{{entry.heading}}</template>
    </hero-module>

    <smart-container :inset="1" :bg="'vl-grey'" :graident="{color: 'purple', position: 'right' }">
      <feature-carousel :data="featureCarouselData"></feature-carousel>
    </smart-container>

    <smart-container :inset="1"> 
      <div class="areas-of-excellence">
        <ul class="intro">
          <li>{{entry.clinicalExcellenceHeading}}</li>
          <li>{{entry.clinicalExcellenceBlurb}}</li>
          <li v-if="entry.citation">{{entry.citation}} <span>{{entry.citationTitle}}</span></li>
        </ul>
        <div class="areas">
          <router-link v-for="(area, index) in clinicalExcellenceArticles" :to="{name: 'clinical-excellence-item', params:{slug: area.slug}}">
            <ul>
              <li class="graident-wrapper">
                <graident-helper :graident="{color: areaColor(index), angle: areaAngle(), start: 25}"></graident-helper>
              </li>
              <li><span>{{area.heading}}</span></li>
              <li class="btn btn-white">Learn More</li>
            </ul>
          </router-link>
        </div>
      </div>
    </smart-container>
    

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import FeatureCarousel from '../FeatureCarousel.vue'
import GraidentHelper from '../helpers/GraidentHelper.vue'
import gql from 'graphql-tag'

  export default {
    components: { HeroModule, SmartContainer, FeatureCarousel, GraidentHelper },
    data() {
      return {
        entry: Object,
        clinicalExcellenceArticles: [],
        featureCarouselData: {
          title: 'OUR<br/>EXPERTISE',
          slides: []
        },
      }
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "clinicalExcellence") {
            id title slug 
            ...on clinicalExcellence_clinicalExcellence_Entry {
              heading subheading
              seoTitle seoDescription
              featuredClinicalExpertise {
                ...on newsArticles_default_Entry {
                 id title slug date: postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
                  category: category {
                    title
                  }
                  heading teaser
                }
              }
              clinicalExcellenceHeading clinicalExcellenceBlurb citation citationTitle
            } 
          }
          clinicalExcellenceArticles: entries(section: "excellenceArticles"){
            id title slug 
            ...on excellenceArticles_default_Entry {
              heading 
            }
          }  
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              let featureCarouselData = this.featureCarouselData
              data.page.featuredClinicalExpertise.forEach(function (article, index) {
                featureCarouselData.slides.push(article)
              });
              console.log(data.page)
              //console.log(featureCarouselData)
              console.log(data.clinicalExcellenceArticles)
              this.clinicalExcellenceArticles = data.clinicalExcellenceArticles
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
      
    },
    methods: {
      areaColor(index) {
        if (index < 2) {
          return 'green'
        }
        if (index < 5) {
          return 'purple'
        }
        return 'blue'
      },
      areaAngle() {
        return Math.floor(Math.random() * 360)
      }
    },
  }
</script>