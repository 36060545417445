<template>
  <div id="about" v-if="craftLoaded && settingsLoaded">
    <hero-module :graident="{ anim: true, color: 'green', angle: 149 }" :subheading="entry.subheading">
      <template v-slot:heading>{{entry.heading}}</template>
    </hero-module>

    <submenu-target :target="'intro'">
      <smart-container :inset="1" :graident="{ color: 'blue', position: 'bl' }">
        <div class="introduction">
          <div class="col">
            <h3>{{entry.simpleIntro}}</h3>
            <div class="wysiwyg" v-html="entry.generalContent"></div>
          </div>
          <div class="col">
            <inline-svg :src="$getImageSrc('nz-map')"></inline-svg>
          </div>
          <div class="col">
            <a href="https://arg.co.nz" target="_blank">
              <img :src="$getImageSrc('auckland-radiology-logo.svg')" alt="Auckland Radiology Logo">
            </a>
            <a href="https://bayradiology.co.nz" target="_blank">
              <img :src="$getImageSrc('bay-radiology-logo.svg')" alt="Bay Radiology Logo">
            </a>
            <a href="https://pacificradiology.com" target="_blank">
              <img :src="$getImageSrc('pacific-radiology-logo.svg')" alt="Pacific Radiology Logo">
            </a>
          </div>
        </div>
      </smart-container>
    </submenu-target>

    <app-submenu :children="['our-teams','structure','partners','radiologists']"></app-submenu>

    <submenu-target :target="'our-teams'">
      <smart-container :inset="1" :bg="'vl-grey'" :graident="{ color: 'blue', position: 'right' }">
        <div class="our-teams">
          <accordion-item v-for="(category, index) in entry.keyStaff" :group-uid="uid" :open="index === 0">
            <template slot="title">{{category.heading}}</template>
            <template slot="byline">{{category.byline}}</template>
            <template slot="content">
              <ul v-for="staff in getGroup(category.profileSet)" class="staff-member">
                <li class="img-wrapper is-cover three-two">
                   <craft-image-helper :src="staff.image"></craft-image-helper>
                </li>
                <li class="name">{{staff.name}}</li>
                <li class="title">{{staff.title}}</li>
                <li class="email" v-if="staff.email"><a :href="`mailto:${staff.email}`">Email {{$getFirstName(staff.name)}}</a></li>
                <li class="description">{{staff.description}}</li>
              </ul>

              <statement-module v-if="category.secondaryBlurb" :bg="false">
                <template v-slot:statement>{{category.secondaryBlurb}}</template>
                <template v-slot:link>
                  <router-link v-if="category.link.length" :to="pageLink(category)" class="btn">{{category.linkLabel}}</router-link>
                </template>
              </statement-module>
            </template>

          </accordion-item>
        </div>
      </smart-container>
    </submenu-target>

    
    <submenu-target :target="'structure'">
      <smart-container :inset="1" :bg="'m-grey'">
        <div class="structure">
          <h3>{{entry.structureHeading}}</h3>
          <div class="wysiwyg" v-html="entry.structureContent"></div>
          <inline-svg :src="entry.structureImage[0].url"></inline-svg>
        </div>
      </smart-container>
    </submenu-target>
    

    <submenu-target :target="'partners'">
      <smart-container :inset="1" :bg="'vl-grey'">
        <image-feature :image-graident="{color: 'blue', position: 'bl'}" :data="imageFeature"></image-feature>
      </smart-container>
    </submenu-target>
    

    <submenu-target :target="'radiologists'">
      <smart-container :inset="1" :graident="{ color: 'green', position: 'tr' }">
        <div class="our-radiologists">
          <h3>{{entry.ourRadiologistsHeading}}</h3>
          <div class="wysiwyg" v-html="entry.ourRadiologistsContent">
          </div>
          <teaser-carousel :data="teaserCarousel"></teaser-carousel>
        </div>
      </smart-container>
    </submenu-target>
    
  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import AppSubmenu from '../AppSubmenu.vue'
import SmartContainer from '../SmartContainer.vue'
import TeaserCarousel from '../TeaserCarousel.vue'
import ImageFeature from '../ImageFeature.vue'
import SubmenuTarget from '../helpers/SubmenuTarget.vue'
import AccordionItem from '../helpers/AccordionItem.vue'
import StatementModule from '../StatementModule.vue'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, AppSubmenu, SmartContainer, TeaserCarousel, ImageFeature, SubmenuTarget, AccordionItem, StatementModule },
  data() {
    return {
      entry: Object,
      board: [],
      executiveTeam: [],
      clinicalAdvisoryGroup: [],
      uid: Date.now() + Math.random(),
      imageFeature: {
        mode: '',
        image: null,
        videoURL: null,
        features: []
      },
      teaserCarousel: {
        mode: 'news', // news | jobs
        slides: []
      },
    }
  },
  computed: {
   
  },
  apollo: {
      page: {
        query: gql`query{
          page: entry(section: "about") {
            id title slug 
            ...on about_about_Entry {
              seoTitle seoDescription
              heading subheading simpleIntro 
              generalContent
              keyStaff: staff{
                ...on staff_group_BlockType{
                  heading byline profileSet 
                  secondaryBlurb 
                  link: secondaryLink {...on EntryInterface{
                      typeHandle slug 
                    } } 
                  linkLabel: secondaryLinkLabel
                }
              }
              structureHeading structureContent
              structureImage {
                url
              }
              partnersImage{
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              features: introLinks{
                ...on introLinks_introContent_BlockType{
                  heading content: introContent linkLabel
                  link: introLink {
                    ...on EntryInterface{
                      typeHandle slug 
                    }
                  }
                }
              }
              ourRadiologistsHeading
              ourRadiologistsContent
              carouselMode
              newsArticles{
                id slug
                ...on newsArticles_default_Entry {
                  heading: title teaser category { id title }
                    image: heroImage {
                      url
                      title
                      width
                      height
                      focalPoint
                      mimeType
                      s400: url @transform(width: 400)
                      s800: url @transform(width: 800)
                      s1200: url @transform(width: 1200)
                      s1600: url @transform(width: 1600)
                      s2000: url @transform(width: 2000)
                      s2400: url @transform(width: 2400)
                      s2800: url @transform(width: 2800)
                      s3200: url @transform(width: 3200)
                    }
                }                  
              }
              jobListings{
                id slug
                ...on jobVacancies_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
                ...on jobVacanciesCMS_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
              }
            } 
          }
          board: entries(section: "boardMembers"){
            id slug
            ...on boardMembers_default_Entry{
              name: profileName title: profileTitle description: profileDescription email: profileEmail
              image: profileImage{
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
              }
            }
          }
          executiveTeam: entries(section: "executiveTeam"){
            id slug
            ...on executiveTeam_default_Entry{
              name: profileName title: profileTitle description: profileDescription email: profileEmail
              image: profileImage{
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
              }
            }
          }
          clinicalAdvisoryGroup: entries(section: "clinicalAdvisoryGroup"){
            id slug
            ...on clinicalAdvisoryGroup_default_Entry{
              name: profileName title: profileTitle description: profileDescription email: profileEmail
              image: profileImage{
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
              }
            }
          }
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              let imageFeature = this.imageFeature
              imageFeature.mode = 'image'
              imageFeature.image = data.page.partnersImage
              imageFeature.features = data.page.features
              let teaserCarousel = this.teaserCarousel
              teaserCarousel.mode = data.page.carouselMode
              if (teaserCarousel.mode === 'news'){
                data.page.newsArticles.forEach(function (article, index) {
                  teaserCarousel.slides.push(article)
                });
              }
              else{
                data.page.jobListings.forEach(function (job, index) {
                  teaserCarousel.slides.push(job)
                });
              }
              console.log(data)
              this.board = data.board
              this.executiveTeam = data.executiveTeam
              this.clinicalAdvisoryGroup = data.clinicalAdvisoryGroup
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
  methods: {
     getGroup(type){
      return this[type]
    },
    pageLink(feature) {
        let routeName = this.$craftVueRouteTransform(feature.link[0].__typename,feature.link[0].slug)
        let result = {
            name: feature.link[0].slug
          }
        if (!routeName){
          return result // 1:1 route name : slug
        }
        else{
          result.name = routeName
          if (feature.link[0].slug) {
            result.params = {
              slug: feature.link[0].slug
            }
          }
          //console.log(result)
        }
        return result
      }
  },
}
</script>