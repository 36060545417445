<template>
     <form :class="state">
        <slot></slot>
        <template v-if="showSubmit">
          <!-- <input type="submit" :value="submitValue" :disabled="state"> -->
          <div class="submit-wrapper">
            <div class="btn submit" @click="submitForm">{{submitValue}}</div>
            <small v-if="recaptcha" class="recaptcha-byline">This site is protected by reCAPTCHA and the <br
                v-if="!$is('xs')">Google
              <a href="https://policies.google.com/privacy">Privacy Policy</a> and
              <a href="https://policies.google.com/terms">Terms of Service</a> apply.
            </small>
          </div>

          
          <ul class="validation" v-if="Object.keys(errors).length">
              <li v-if="errors.length > 1">Errors.</li>
              <li v-else>Error.</li>
              <li v-for="error in errors">{{ error[0] }}</li>
          </ul>
        </template>
    </form>
</template>

<script>
  const CV_MAX_SIZE_MB = 10

  import { load } from 'recaptcha-v3'

  export default {
    props: {
      submit: {
        type: String,
        default: 'Submit'
      },
      recaptcha: {
        type: Boolean,
        default: true
      },
      extra: {
        type: Object,
        default() {
          return {}
        }
      },
      showSubmit: {
        type: Boolean,
        default: false
      },
      cv:{
        type: Object,
        default: null
      }
    },
    data(){
      return {
        endpoint: '/wheelform/message/send',
        errors: false,
        state: null,
        recaptchaToken:null,
        response: ''
      }
    },
    computed: {
      submitValue() {
        if (this.state === 'processing') {
          return 'Processing...'
        } else if (this.state === 'success') {
          return 'Thanks, We\'ll be in touch.'
        } else {
          return this.submit
        }
      }
    },
    methods: {
      fetchCSRF(){
        this.axios.get('/csrf-token').then(res => {
          // console.log(res)
          this.$store.commit('setCSRFTokenName', res.data.csrfTokenName)
          this.$store.commit('setCSRFToken', res.data.csrfToken)
          console.log('CSRF retrieve...')
          console.log(res.data)
        }).catch(error => {
          // if(error.response.status === 500) this.$router.push({name: 'error', params: {status: error.response.status}})
          console.log(error)
        });
      },
      serverResponse(response){
        if (response.data.success){
          this.$emit('success', response.message)
          this.state = 'success'
        }
        else{
          this.state = null
          this.errors = response.data.errors
        }
      },
      serverError(response){
        console.log(response)
        this.state = null
        //this.errors.push('There was a problem submitting your form. Please try again later')
        this.errors = response.data.errors
      },
      submitForm: function(e) {
        
        const recaptcha = this.$recaptchaInstance
        // console.log(recaptcha)
        // // Execute reCAPTCHA with action "login".
        load('6LeB5QwjAAAAACjA8uiwexMetD3d2KvXUGRGeJzb').then((recaptcha) => {
          recaptcha.execute('test').then((token) => {
              console.log(token) // Will print the token
              this.recaptchaToken = token

              // reset 
              this.state = 'processing'
              this.errors = false
              let fileUpload = false
              let routeName = this.$route.name
              const params = new URLSearchParams({ foo: 'bar' });
              params.append('action', '/wheelform/message/send');
              // console.log(e.currentTarget.parentElement)
              let form = document.getElementById('registrationForm')


              for (const el of form.elements) {

                if(!el.name || el.type === 'submit' || el.name === 'terms') continue;
                if (el.type === 'file'){
                  // special handling for files
                  fileUpload = true
                  params.append(el.name,el.files[0])
                }
                else{
                  params.append(el.name,el.value)
                }              
              }
              let payLoad = params

              if (fileUpload){
                // Rebuild as formdata
                let formData = new FormData();
                for (const el of form.elements) {
                  if(!el.name || el.type === 'submit' || el.name === 'terms') continue;
                  if (el.type === 'file'){
                    // special handling for files
                    fileUpload = true
                    formData.append(el.name,el.files[0])
                    if (el.files[0]){
                      var fileSize = el.files[0].size / 1024 / 1024
                      if (fileSize > CV_MAX_SIZE_MB){
                        let fileSizeErrors = {file:["Please limit CV file to 10MB or smaller in size."]}
                        this.errors = fileSizeErrors
                        this.state = null
                        return false
                      }
                      var fileExtension = el.files[0].name.split('.').pop()
                      if (fileExtension != "pdf" && fileExtension && "docx" && fileExtension != "doc" && fileExtension != 'txt'){
                        let fileTypeErrors = {file:["Please use only Word, PDF or txt format for your CV file."]}
                        this.errors = fileTypeErrors
                        this.state = null
                        return false
                      }
                    }
                  }
                  else{
                    formData.append(el.name,el.value)
                  }              
                }
                formData.append('g-recaptcha-response', this.recaptchaToken);
                formData.append(this.$store.state.csrfTokenName, this.$store.state.csrfToken);
                console.log('g-recaptcha-response ' + this.recaptchaToken)
                console.log('csrfTokenName ' + this.$store.state.csrfTokenName)
                console.log('csrfToken ' + this.$store.state.csrfToken)
                payLoad = formData

                var optionAxios = {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                }
              }
              else{
                payLoad.append('g-recaptcha-response', this.recaptchaToken);
                payLoad.append(this.$store.state.csrfTokenName, this.$store.state.csrfToken);
                var optionAxios = {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                  }
                }
              }
              this.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
              return new Promise((serverResponse, serverError) => {
                this.axios.post('/wheelform/message/send', payLoad, optionAxios)
                  .then((response) => this.serverResponse(response))
                  .catch((err) => this.serverError(err));
              });
            })
        })
      },
    },
    created(){
      this.fetchCSRF();
    }
  }
</script>