<template>
  <div class="img-wrapper has-graident is-cover" :class="ratioClasses" >
    <graident-helper :graident="graidentData"></graident-helper>
    <craft-image-helper :src="src"></craft-image-helper>
    <!--<img :src="$getImageSrc(src)">-->
  </div>
</template>

<script>
import GraidentHelper from './GraidentHelper.vue'

  export default {
  components: { GraidentHelper },
    props: {
      src: {
        type: [Array, String],
        required: true
      },
      ratio: {
        type: [String, Boolean],
        default: false,
      },
      graident: {
        type: [Object, Boolean],
        default: false,
      },
    },
    data() {
      return {
      }
    },
    computed: {
      ratioClasses() {
        return (this.ratio) ? this.ratio : false
      },
      graidentData() {
        if(this.graident) {
          let result = this.graident
          if (!result.opacity) {
            result.opacity = 1
          }
          return result
        }
      }
    },
    methods: {
    }
  }
</script>