<template>
  <div id="job-listing" v-if="settingsLoaded && craftLoaded">
    <hero-module>
      <template v-slot:heading>{{entry.title}}</template>
    </hero-module>
    
    <smart-container :inset="2">
      <div class="listing-wrapper">
        <ul class="details">
          <li>LOCATION: {{entry.location[0].title}}</li>
          <li>LISTED: {{entry.listed}}</li>
          <li>APPLY BY: {{applyByDate(entry)}}</li>
          <li>POSITION TYPE: {{entry.type[0].title}}</li>
          <li v-if="entry.__typename !== 'jobVacanciesCMS_default_Entry'">JOB ID: {{entry.jobId}}</li>
          <li @click="applyLink()">CLICK HERE TO APPLY</li>
        </ul>
        <div class="introduction">{{entry.summary}}</div>
        <div v-if="entry.jobDescription" class="ja-description wysiwyg" v-html="entry.jobDescription"></div>
        <div v-if="entry.secondaryContent" class="description wysiwyg" v-html="entry.secondaryContent"></div>
        <div class="apply-btn btn" @click="applyLink()">APPLY NOW</div>
      </div>
    </smart-container>

    
    <smart-container v-if="entry.showImageVideoBlock" :inset="2" class="image-video-wrapper">
      <image-video-block :data="imageVideoData"></image-video-block>
    </smart-container>
    
    <smart-container v-if="regionContent" :inset="2" class="region-content-wrapper">
      <div class="region-content wysiwyg" v-html="regionContent"></div>
    </smart-container>

    <smart-container v-if="showForm" :inset="2" class="form-wrapper">
      
      <form-helper id="registrationForm" :submit="'Submit'" :recaptcha="true" :showSubmit="showSubmit">
        <input type="hidden" name="form_id" value="1">
        <input type="hidden" name="role" :value="entry.title">
        <p>For information on career opportunities at RHCNZ, please fill out this form.</p>
        <div class="input-wrapper">
          <input type="text" name="name" placeholder="NAME *" v-model="name">
          <input type="text" name="email" placeholder="EMAIL *" v-model="email">
          <input type="text" name="phone" placeholder="PHONE *" v-model="phone">
          <!--<textarea id="message" name="message" v-model="message" placeholder="MESSAGE"></textarea>-->
        </div>
        
        <div class="upload-wrapper">
          <p>Upload your resume.</p>
          <p class="footnote">File must be a PDF or Microsoft Word document.<br />Max file size 10Mb.</p>
          <input type="file" name="cv" placeholder="UPLOAD YOUR FILE" @change="getFile">
        </div>

        <div class="terms-wrapper">
          <input type="checkbox" name="terms" id="terms" v-model="showSubmit">
          <label for="terms">I have read and agree with the <router-link :to="{name: 'fineprint'}">Terms and Conditions</router-link>.</label>
        </div>
        
      </form-helper>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import ImageVideoBlock from "~/components/ImageVideoBlock.vue";
import FormHelper from '~/components/helpers/FormHelper'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, SmartContainer, ImageVideoBlock, FormHelper},
  data() {
    return {
      entry: Object,
      name: null,
      email: null,
      phone: null,
      cv: null,
      //message: null,
      terms: null,
      showSubmit: false,
    }
  },
  apollo: {
      page: {
        query: gql` query variables($slug: [String!]){
          page: entry(section: ["jobVacancies","jobVacanciesCMS"],slug: $slug) {
            ...on jobVacancies_default_Entry{

              title
              listed: postDate @formatDateTime (format: "d F Y", timezone: "Pacific/Auckland")
              jobId
              location: jobRegion{
                id title
              }
              speciality: jobCategory{
                id title
              }
              type: jobType{
                id title
              }
              subsidiaryBrand: subsidiary{
                id title
              }
              featured{
                id title
              }
              applyBy
              summary
              registrationLink
              jobDescription
              secondaryContent
              showImageVideoBlock
              imageVideoBlockMode 
              image {
                 ...on general_Asset{
                  caption
                }
                ...on news_Asset{
                  caption
                }
                ...on staff_Asset{
                  caption
                }
                
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              videoURL
              imageVideoBlockContent
            }
            ...on jobVacanciesCMS_default_Entry{
              seoTitle seoDescription
              title
              listed: postDate @formatDateTime (format: "d F Y", timezone: "Pacific/Auckland")
              location: jobRegion{
                id title
              }
              speciality: jobCategory{
                id title
              }
              type: jobType{
                id title
              }
              subsidiaryBrand: subsidiary{
                id title
              }
              featured{
                id title
              }
              applyByDate @formatDateTime (format: "d F Y", timezone: "Pacific/Auckland")
              shortDescription
              summary
              jobDescription
              secondaryContent
              showImageVideoBlock
              imageVideoBlockMode 
              image {
                 ...on general_Asset{
                  caption
                }
                ...on news_Asset{
                  caption
                }
                ...on staff_Asset{
                  caption
                }
                
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              videoURL
              imageVideoBlockContent
            }
          }     
        }
        `,
        variables() {      
          return {slug: this.$route.params.slug}
        },
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if(data.page.registrationLink){
                this.$store.commit('setTitle',data.page.title)
                this.$store.commit('setDescription',this.seoDescription(data.page))
              }
              else{
                if (data.page.seoTitle !== null)
                  this.$store.commit('setTitle',data.page.seoTitle)
                else
                  this.$store.commit('setTitle',data.page.title)
                if (data.page.seoDescription !== null)
                  this.$store.commit('setDescription',data.page.seoDescription)
                else
                  this.$store.commit('setDescription','')
              }
              //console.log(data.page)
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 404}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
  computed: {
    regionContent(){
      let regionContent = false
      let listing = this.entry
      this.$store.state.regionList.forEach(function (region, index) {
        if (listing.location[0].id === region.id && region.regionBlurb !== null)
          regionContent = region.regionBlurb
      });
      return regionContent
    }, 
    imageVideoData() {
      let result = {}
      if (this.entry.showImageVideoBlock) {
        result.mode = this.entry.imageVideoBlockMode
        result.copy = this.entry.imageVideoBlockContent
        if (result.mode === 'image') {
          result.src = this.entry.image
        } else {
          result.src = this.entry.videoURL
        }
        return result
      } else {
        return false
      }
    },
    showForm(){
      return this.entry.__typename === 'jobVacanciesCMS_default_Entry'
    }
  },
  methods: {
    getFile(event) {
      if(event.target.files[0] !== undefined){
        this.cv = event.target.files[0]
      } else {
        this.cv = null
      }
    },
    shortDescription(entry){
      if (entry.registrationLink){
        var customCleaned = entry.applyBy.replace(/[\[\]']+/g,'')
        var customArray = customCleaned.split('",');
        if (customArray.length>1){
          var shortDescription = customArray[2]
          var cleanShortDescription = shortDescription.replace(/['"]+/g, '')
          return cleanShortDescription
        }
        else
          return ''
      }
      else
        return entry.shortDescription
    },
    applyByDate(entry){
      if (entry.registrationLink){
        // JobAdder - must split custom fields
        var customCleaned = entry.applyBy.replace(/[\[\]']+/g,'')
        var customArray = customCleaned.split('",');
        var applyByDate = customArray[0]
        var cleanApplyByDate = applyByDate.replace(/['"]+/g, '')
        return cleanApplyByDate
      }
      else
        return entry.applyByDate
    },
    seoDescription(entry){
      // JobAdder - must split custom fields for SEO
      var customCleaned = entry.applyBy.replace(/[\[\]']+/g,'')
      var customArray = customCleaned.split('",');
      if (customArray.length){
        var seoDescription = customArray[1]
        return seoDescription
      }
      else
        return ''
    },
    applyLink(entry) {
      if (this.entry.__typename === 'jobVacanciesCMS_default_Entry'){
        this.$scrollTo('#registrationForm', 500, { offset: 0 })
      } else {
        window.open(this.entry.registrationLink, '_blank')
      }
    },

  }
}
</script>