<template>
  <div id="error" class="container">
    <div class="inner">
      <h1>500 Error.</h1>
      <p>Our server is down, please check back later.</p>
    </div>
  </div>
</template>


<script>

export default {
  data() {
    return {
    }
  },
  computed: {
  }
}
</script>