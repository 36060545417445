<template>
  <div id="contact"  v-if="craftLoaded && settingsLoaded">

    <hero-module :graident="{ anim: true, color: 'blue', angle: 15 }" :subheading="entry.subheading">
      <template v-slot:heading>{{ entry.heading }}</template>
    </hero-module>

    <smart-container :inset="1">
      <div class="services">
        <h3 v-if="entry.servicesHeading">{{entry.servicesHeading}}</h3>
        <p v-if="entry.servicesBlurb">{{entry.servicesBlurb}}</p>
        <div class="brands-wrapper">
          <div class="brands-inner">
            <ul class="brand">
              <li>
                <img :src="$getImageSrc('auckland-radiology-logo.svg')" alt="Auckland Radiology Logo"></img>
              </li>
              <li>{{entry.aucklandRadiologyBlurb}}</li>
              <li><button @click="openLink(entry.aucklandRadiologyBookingLink)" style="background-color: rgb(0, 156, 222);border: none;color: white;padding: 15px;text-align: center;text-decoration: none;display: inline-block;font-size: 14px;font-weight: bold;margin: 4px 2px;cursor: pointer;border-radius:14px">Book an Appointment</button></li>
            </ul>
            <ul class="brand">
              <li>
                <img :src="$getImageSrc('bay-radiology-logo.svg')" alt="Bay Radiology Logo"></img>
              </li>
              <li>{{entry.bayRadiologyBlurb}}</li>
              <li><button @click="openLink(entry.bayRadiologyBookingLink)" style="background-color: rgb(164, 199, 76);border: none;color: white;padding: 15px;text-align: center;text-decoration: none;display: inline-block;font-size: 14px;font-weight: bold;margin: 4px 2px;cursor: pointer;border-radius:14px">Book an Appointment</button></li>
            </ul>
            <ul class="brand">
              <li>
                <img :src="$getImageSrc('pacific-radiology-logo.svg')" alt="Pacific Radiology Logo"></img>
              </li>
              <li>{{entry.pacificRadiologyBlurb}}</li>
              <li><button @click="openLink(entry.pacificRadiologyBookingLink)" style="background-color:rgb(97, 85, 154) ;border: none;color: white;padding: 15px;text-align: center;text-decoration: none;display: inline-block;font-size: 14px;font-weight: bold;margin: 4px 2px;cursor: pointer;border-radius:14px">Book an Appointment</button></li>
            </ul>
          </div>
        </div>
      </div>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, SmartContainer },
  data() {
    return {
      entry: Object,
      executiveTeam: [],
    }
  },
  computed: {
  },
  apollo: {
      page: {
        query: gql`query{
          page: entry(section: "bookings") {
            id title slug 
            ...on bookings_bookings_Entry {
              seoTitle seoDescription
              heading subheading 
              servicesHeading servicesBlurb aucklandRadiologyBlurb aucklandRadiologyBookingLink bayRadiologyBlurb bayRadiologyBookingLink pacificRadiologyBlurb pacificRadiologyBookingLink
              }
            } 
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              console.log(data)
              this.executiveTeam = data.executiveTeam
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
  methods: {
    openLink(url) {
      window.open(url, '_blank');
    }
  },
}
</script>