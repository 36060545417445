<template>
  <div class="statement-module" :class="classes">
    <p v-if="hasStatement" class="big"><slot name="statement"></slot></p>
    <p v-if="hasCite" class="v-small"><slot name="cite"></slot></p>
    <slot v-if="hasLink" name="link"></slot>
  </div>
</template>

<script>


  export default {
    components : {  },
    props: {
      bg: {
        type: [Boolean],
        default: true,
      },
      borders: {
        type: [String, Array, Boolean],
        default: false
      },
      inset: {
        type: [Boolean],
        default: true
      },
    },
    data() {
      return {
      }
    },
    computed: {
      hasStatement() {
        return this.$slots.statement
      },
      hasCite() {
        return this.$slots.cite
      },
      hasLink() {
        return this.$slots.link
      },
      classes() {
        let result = []
        if(this.borders) {
          //if borders is a string, add it to the class list
          if(typeof this.borders === 'string') {
            result.push(`border-${this.borders}`)
          } else {
            // if borders is an array, add each item to the class list
            this.borders.forEach(border => {
              result.push(`border-${border}`)
            })
          }
        }
        // if bg is set, add it to the class list
        if(this.bg) {
          result.push('has-bg')
          result.push('inset')
        }
        // if inset is set and not already in results array, add it to the class list
        if(this.inset && !result.includes('inset')) {
          result.push('inset')
        }
        return result
      },
    },
    methods: {
    }
  }
</script>