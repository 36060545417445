<template>
  <footer v-if="craftLoaded && settingsLoaded">

      <ul class="container inset">
        <li>
          <img :src="$getImageSrc('RHCNZ-logo-color.svg')" alt="RHCNZ Logo" @click="top()">
        </li>
        <li>woRLd-cLass raDiOLogY <br/>DELivERED LocALLy.</li>

        <li>
          <ul>
            <li>VISIT OUR RADIOLOGY GROUP WEBSITES</li>
            <li>
              <a href="https://arg.co.nz" target="_blank">
                <img :src="$getImageSrc('auckland-radiology-logo.svg')" alt="Auckland Radiology Logo">
              </a>
            </li>
            <li>
              <a href="https://bayradiology.co.nz" target="_blank">
                <img :src="$getImageSrc('bay-radiology-logo.svg')" alt="Bay Radiology Logo">
              </a>
            </li>
            <li>
              <a href="https://pacificradiology.com" target="_blank">
                <img :src="$getImageSrc('pacific-radiology-logo.svg')" alt="Pacific Radiology Logo">
              </a>
            </li>
          </ul>
        </li>

        <li>
          <ul :class="socialMediaCount">
            <li v-if="this.$store.state.facebookUrl">
              <a :href="this.$store.state.facebookUrl" target="_blank">
                <img :src="$getImageSrc('facebook.svg')" alt="Facebook">
              </a>
            </li>
            <li v-if="this.$store.state.linkedInUrl">
              <a :href="this.$store.state.linkedInUrl" target="_blank">
                <img :src="$getImageSrc('linkedin.svg')" alt="LinkedIn">
              </a>
            </li>
          </ul>
        </li>

        <li>
          <ul>
            <li>
              <router-link :to="{name: 'fineprint'}">PRIVACY & TERMS</router-link>
            </li>
            <li>© RHCNZ {{year}}</li>
          </ul>
        </li>
      </ul>

  </footer>
</template>


<script>
  var VueScrollTo = require('vue-scrollto');

  export default {
    components: { },
    data(){
      return {
      }
    },
    computed: {
      year() {
        return new Date().getFullYear();
      },
      socialMediaCount(){
        let counter = 0
        if (this.$store.state.facebookUrl)
          counter++
        if (this.$store.state.linkedInUrl)
          counter++
        return 'col-'+counter
      }
    },
    methods: {
      top() {
        VueScrollTo.scrollTo('main')
      }
    }
  }
</script>