<template>
  <div id="fineprint" v-if="craftLoaded && settingsLoaded">
    
    <hero-module>
      <template v-slot:heading>Privacy Policy &<br/> Terms of use</template>
    </hero-module>

    <smart-container :inset="2">
        <h2>Privacy Policy</h2>
        <p v-if="entry.privacyIntro" class="big">{{entry.privacyIntro}}</p>
        <div class="wysiwyg" v-html="entry.privacy"></div>
        <a name="terms" id="terms"></a>
        <hr>
        <h2>Terms of use</h2>
        <p v-if="entry.termsIntro" class="big">{{entry.termsIntro}}</p>
        <div class="wysiwyg" v-html="entry.terms"></div>
    </smart-container>
  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import gql from 'graphql-tag'

  export default {
    components : { HeroModule, SmartContainer },
    data() {
      return {
        entry: Object,
      }
    },
    computed: {
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "privacyTerms") {
            id title slug 
            ...on privacyTerms_privacyTerms_Entry {
              seoTitle seoDescription
              privacyIntro privacy: fullWysiwyg termsIntro terms: fullWysiwygB
              }
            } 
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              console.log(data)
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
    },
    mounted(){
      window.addEventListener('load', (event) => {
        let section = this.$route.hash.replace("#", "");
        if (section){
          window.document.getElementById(section).scrollIntoView()
        }
      });
    }
  }
</script>