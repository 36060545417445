
import Home from './components/pages/Home'
import About from './components/pages/About'
import ClinicalExcellence from './components/pages/ClinicalExcellence'
import ClinicalExcellenceItem from './components/pages/ClinicalExcellenceItem'
import Community from './components/pages/Community'
import JoinOurTeam from './components/pages/JoinOurTeam'
import JobListing from './components/pages/JobListing'
import News from './components/pages/News'
import NewsArticle from './components/pages/NewsArticle'
import Contact from './components/pages/Contact'
import Bookings from './components/pages/Bookings'
import Fineprint from './components/pages/Fineprint'
import BookingPrivacyFineprint from './components/pages/BookingPrivacyFineprint'
import BookingTermsFineprint from './components/pages/BookingTermsFineprint'
import ErrorServerPage from './components/pages/ErrorServer'
import ErrorPage from './components/pages/Error'


let routes = [
    {   
        path: '/', 
        name: 'home',
        component: Home,
        meta: { title: 'Home' }
    },
    {
        path: '/about',
        name: 'about',
        component: About,
        meta: { title: 'About' }
    },
    {
        path: '/clinical-excellence',
        name: 'clinical-excellence',
        component: ClinicalExcellence,
        meta: { title: 'Clinical Excellence' }
    },
    {
        path: '/clinical-excellence/:slug',
        name: 'clinical-excellence-item',
        component: ClinicalExcellenceItem,
        
    },
    {
        path: '/community',
        name: 'community',
        component: Community,
        meta: { title: 'Community' }
    },
    {
        path: '/join-our-team',
        name: 'join-our-team',
        component: JoinOurTeam,
        meta: { title: 'Join Our Team' }
    },
    {
        path: '/join-our-team/:slug',
        name: 'job-listing',
        component: JobListing,
    },
    {
        path: '/news',
        name: 'news',
        component: News,
        meta: { title: 'News' },
    },
    {
        path: '/news/:slug',
        name: 'news-article',
        component: NewsArticle,
    },
    {
        path: '/contact',
        name: 'contact',
        component: Contact,
        meta: { title: 'Contact' },
    },
    {
        path: '/bookings',
        name: 'bookings',
        component: Bookings,
        meta: { title: 'Bookings' },
    },
    {
        path: '/privacy-terms',
        name: 'fineprint',
        component: Fineprint,
        meta: { title: 'Privacy & Terms' },
    },
    {
        path: '/booking-privacy-policy',
        name: 'bookingPrivacyFineprint',
        component: BookingPrivacyFineprint,
        meta: { title: 'Booking Privacy Policy' },
    },
    {
        path: '/online-terms-and-conditions',
        name: 'bookingTermsFineprint',
        component: BookingTermsFineprint,
        meta: { title: 'Online Terms & Conditions' },
    },
    {
        path: '/500',
        name: 'errorServer',
        component: ErrorServerPage,
        meta: { title: 'Error' },
    },
    { 
        path: "*",
        name: 'error',
        component: ErrorPage,
        meta: { 
            title: 'Error.',
            hidden: true 
        },
    }
]

export default {
    mode: 'history',
    routes: routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
}