
import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import Axios from 'axios';
import VueAxios from 'vue-axios';
import VueScrollTo from 'vue-scrollto'
import VueGtag from 'vue-gtag'
import Meta from 'vue-meta'
import Store from './store.js'
import Router from './router.js'
import Helpers from './helpers.js'
import {InlineSvgPlugin} from 'vue-inline-svg';
import VueObserveVisibility from 'vue-observe-visibility'
import VScrollLock from 'v-scroll-lock'

import CraftImageHelper from './components/helpers/CraftImageHelper.vue'
import App from './components/App.vue'

import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import schema from './schema.json'
import VueApollo from 'vue-apollo'
import { VueReCaptcha } from 'vue-recaptcha-v3'

Vue.use(Vuex)
Vue.use(VueRouter)
Vue.use(VueAxios, Axios);
Vue.use(VueScrollTo)
Vue.use(Helpers)
Vue.use(Meta)
Vue.use(InlineSvgPlugin);
Vue.use(VueObserveVisibility)
Vue.use(VScrollLock)
Vue.use(VueApollo)

Vue.component('craft-image-helper', CraftImageHelper)

// Vue.use(VueGoogleMaps, {
// 	load: {
// 		key: 'AIzaSyDUhhXKEBj130w90wPP3MIT_M6VdtZoT4o',
// 		// libraries: 'geometry',
// 	},

Vue.use(VueReCaptcha, { siteKey: '6LeB5QwjAAAAACjA8uiwexMetD3d2KvXUGRGeJzb',loaderOptions: {
    autoHideBadge: true
  } })

let axiosBaseDomain = process.env.BASE_URL

let routerImport = Router
const host = window.location.host;
axiosBaseDomain = process.env.BASE_URL

Axios.defaults.baseURL = axiosBaseDomain;
let m = document.location.href.match(/\btoken=([^&]+)/);
let token = m ? m[1] : '';
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: `${axiosBaseDomain}/api?token=${token}`
})

// Retrieve schema map
const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: schema
})

// Cache implementation
const cache = new InMemoryCache({fragmentMatcher})

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
})

const router = new VueRouter(Router)
const store = new Vuex.Store(Store);


router.beforeEach((to, from, next) => {
  store.commit('setCraftLoaded', false)
  store.commit('setCraftPageData', null)
  next();
});

Vue.mixin({
  computed: {
   craftLoaded: {
     get() {
       return this.$store.state.craftLoaded
     }
    },
    settingsLoaded: {
     get() {
       return this.$store.state.settingsLoaded
     }
    },
  }
})

Vue.use(VueGtag, {
  config: { id: "GTM-5CKXZ4D" }
}, router);

new Vue({
	router,
	store,
	apolloProvider,
	render: createElement => createElement(App)
}).$mount('#app');