<template>
  <div id="community"   v-if="craftLoaded && settingsLoaded">
    <hero-module :graident="{anim: true, color: 'purple', angle: 35}" :subheading="entry.subheading">
      <template v-slot:heading>{{entry.heading}}</template>
    </hero-module>

    <smart-container :inset="1" :graident="{color: 'green', position: 'br'}">
      <div class="intro-wrapper">
        <div class="intro">
          <h3>{{entry.introTitle}}</h3>
          <div class="wysiwyg" v-html="entry.introCopy"></div>
        </div>
       
        <image-video-block v-if="imageVideoData" :data="imageVideoData" :class="{inset : inset}"></image-video-block>
        
        <statement-module v-if="entry.introStatement || entry.introCite" :borders="'top'" :bg="false" :inset="inset">
          <template v-slot:statement>{{entry.introStatement}}</template>
          <template v-slot:cite>{{entry.introCite}}</template>
        </statement-module>
      </div>
    </smart-container>

    <smart-container :inset="1">
      <accordion-item v-for="(accordion, index) in entry.accordion" :group-uid="uid" :open="(index === 0)">
        <template slot="title">{{accordion.title}}</template>
        <template slot="byline">{{accordion.byline}}</template>
        <template slot="content">
          <div class="wysiwyg" v-html="accordion.copy"></div>
          <div class="assets-wrapper">
            <div v-if="accordion.image.length" class="img-wrapper is-cover three-two">
              <craft-image-helper :src="accordion.image"></craft-image-helper>
            </div>
            <video-helper v-if="accordion.videoURL !== null" :src="accordion.videoURL"></video-helper>
          </div>
          <statement-module v-if="accordion.statement !== ''">
            <template v-slot:statement>{{accordion.statement}}</template>
            <template v-slot:cite>{{accordion.citation}}</template>
          </statement-module>
        </template>
      </accordion-item>
    </smart-container>

    <smart-container :inset="1">
      <teaser-carousel :data="teaserCarousel"></teaser-carousel>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import TeaserCarousel from '../TeaserCarousel.vue'
import AccordionItem from '../helpers/AccordionItem.vue'
import StatementModule from '../StatementModule.vue'
import ImageVideoBlock from '../ImageVideoBlock.vue'
import VideoHelper from '../helpers/VideoHelper.vue'
import gql from 'graphql-tag'

export default {
  components: { HeroModule, SmartContainer, TeaserCarousel, AccordionItem, StatementModule, ImageVideoBlock, VideoHelper },
  data() {
    return {
      entry: Object,
      uid: Date.now() + Math.random(),
    }
  },
  apollo: {
      page: {
        query: gql`query{
          page: entry(section: "community") {
            id title slug 
            ...on community_community_Entry {
              seoTitle seoDescription
              heading subheading
              introTitle: communityIntroTitle
              introCopy: communityIntroContent
              showImageVideoBlock
              imageVideoBlockMode 
              image {
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              videoURL imageVideoBlockContent
              introStatement: communityStatement
              introCite: communityCitation
              accordion{
                ...on accordion_detail_BlockType{
                  title: accordionTitle
                  byline: accordionByline
                  copy: accordionCopy
                  image: accordionImage{
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                  videoURL
                  statement: accordionStatement
                  citation: accordionCitation
                }
              }
              featureCarousel 
              carouselMode
              newsArticles{
                id slug
                ...on newsArticles_default_Entry {
                  heading: title teaser category { id title }
                    image: heroImage {
                      url
                      title
                      width
                      height
                      focalPoint
                      mimeType
                      s400: url @transform(width: 400)
                      s800: url @transform(width: 800)
                      s1200: url @transform(width: 1200)
                      s1600: url @transform(width: 1600)
                      s2000: url @transform(width: 2000)
                      s2400: url @transform(width: 2400)
                      s2800: url @transform(width: 2800)
                      s3200: url @transform(width: 3200)
                    }
                }                  
              }
              jobListings{
                id slug
                ...on jobVacancies_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
                ...on jobVacanciesCMS_default_Entry{
                  heading: title teaser: summary
                  image: heroImage {
                    url
                    title
                    width
                    height
                    focalPoint
                    mimeType
                    s400: url @transform(width: 400)
                    s800: url @transform(width: 800)
                    s1200: url @transform(width: 1200)
                    s1600: url @transform(width: 1600)
                    s2000: url @transform(width: 2000)
                    s2400: url @transform(width: 2400)
                    s2800: url @transform(width: 2800)
                    s3200: url @transform(width: 3200)
                  }
                }
              }
            } 
          }     
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              console.log(this.entry)
              
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')

              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
  computed: {
    teaserCarousel(){
      if (this.entry.carouselMode === 'news'){
        let teaserCarousel = {mode: 'news'}
        teaserCarousel.mode = 'news'
        teaserCarousel.slides = this.entry.newsArticles
        return teaserCarousel
      }
      else{
        let teaserCarousel =  {mode: 'jobs'}
        teaserCarousel.slides = this.entry.jobListings
        return teaserCarousel
      }
    },
    imageVideoData() {
      let result = {}
      if (this.entry.showImageVideoBlock) {
        result.mode = this.entry.imageVideoBlockMode
        result.copy = this.entry.imageVideoBlockContent
        if (result.mode === 'image') {
          result.src = this.entry.image
        } else {
          result.src = this.entry.videoURL
        }
        return result
      } else {
        return false
      }
    },
    inset() {
      return (this.$store.state.breakpoint !== 'xs' && this.$store.state.breakpoint !== 'sm')
    }
  },
  methods: {
  },
}
</script>