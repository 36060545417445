import Images from './assets/img/**/*.*'
import Videos from './assets/video/**/*.*'
import Store from './store.js'

var Helpers = {}

Helpers.getImageSrc = (target) => {
  return Helpers.getSrc(target,Images);
}

Helpers.getVideoSrc = (target) => {
  return Helpers.getSrc(target,Videos);
}

Helpers.getSrc = (target, src) => {
  if(target !== undefined) {
    if(target.charAt(0) === '/') {
      target = target.substr(1); // remove leading slash if it was left in by accident
    }
    target = target.split('.')[0] // remove ext
    target = target.split('/') // split on slashes
  }
  target.forEach(function (value, index) {
    src = src[value]
  });

  if(Object.keys(src).length === 1) {
    return src[Object.keys(src)[0]]; // returns string
  } else {
    return src // returns object
  }
}

Helpers.fullUrl = (target) => {
  if(target !== undefined) {
    return 'https://***' + target
  }
}

Helpers.formatUrl = (url) => {
  if(url !== undefined) {
    if(url.startsWith('https://')) {
      url = url.split('https://')[1]
    }
    if(url.startsWith('http://')) {
      url = url.split('http://')[1]
    }
    if(url.startsWith('www.')) {
      url = url.split('www.')[1]
    }
    if(url.includes('/')) {
      url = url.split('/')[0];
    }
    return url
  }
}

Helpers.toKebab = (value) => {
  if(typeof value !== "string") {
    return value
  } else {
  	value = value.toLowerCase()
  	value = value.replace(/[^a-zA-Z0-9 ]/g, '-') // remove specials
  	value = value.replace(/ /g, '-') // remove spaces
  	value = value.replace(/-{1,}/g, '-') // remove multiple dashes
  	return value
  }
}

Helpers.toTitle = (value) => {
  if (typeof value !== "string") {
    return value
  } else {
    if (value.includes('-')) {
      value = value.replace(/-/g, ' ') // replace hyphens
      return value.replace(
        /\w\S*/g,
        function (txt) {
          value = value.replace(/ /g, '-') // remove spaces
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      )
    } else {
      let result = value.replace(/([A-Z])/g, ' $1');
      return result.charAt(0).toUpperCase() + result.slice(1);
    }
  }
}


Helpers.formatTel = (number) => {
  if(number === undefined) {
    return false
  }
  // number = number.replace(/\s/g, '')
  if(number.startsWith('0')) {
    number = number.substring(1)
  }
  number = '+64 ' + number
  return number
}

// useage : <p class="foo" v-html="$wrapText('p','foo', property.description)"></p>
Helpers.wrapText = (tag,className,data) => {
  let startTag = '<' + tag + '>'
  let endTag = '</' + tag + '>'
  if (className) {
    startTag = '<' + tag + ' class="' + className + '">';
  }
  if(data) {
    return data.replace(/\r\n\r\n?/g, endTag + startTag);
  }
}

Helpers.is = (breakpoint) => {
  return Store.state.breakpoint === breakpoint
}


Helpers.getFirstName = (name) => {
  name = name.split(' ');
  let compare = name;

  // remove punctuation
  compare = compare.map((item) => {
    return item.replace(/[^a-zA-Z ]/g, "").toLowerCase();
  });
  return (compare[0] === 'dr') ? name[1] : name[0];
}


Helpers.craftVueRouteTransform = (craftTypeName, slug) => {
  let topLevelRoutes = ['about','clinical-excellence','community','contact','home','join-our-team','news','privacy-and-terms']

  if (topLevelRoutes.indexOf(slug) !== -1){
    // top level match return slug 'as' route name
    return false
  }
  else{
    switch (craftTypeName){
      case 'excellenceArticles_default_Entry':
        return 'clinical-excellence-item'
      break;
      case 'newsArticles_default_Entry':
        return 'news-article'
      break;
      case 'jobVacancies_default_Entry':
      case 'jobVacanciesCMS_default_Entry':
        return 'job-listing'
      break;
    }
  }

}


Helpers.updateCssVars = () => {
  let vh = window.innerHeight / 100
  document.documentElement.style.setProperty('--vh', vh + 'px'); // calc(var(--vh, 1vh) * 100);
  let vw = window.innerWidth / 100
  document.documentElement.style.setProperty('--vw', vw + 'px'); // calc(var(--vw, 1vw) * 100);
}


const install = function(Vue, options){
	Vue.prototype.$getImageSrc 				    = Helpers.getImageSrc
	Vue.prototype.$getVideoSrc 				    = Helpers.getVideoSrc
  Vue.prototype.$fullUrl                = Helpers.fullUrl
	Vue.prototype.$formatUrl 				      = Helpers.formatUrl
	Vue.prototype.$toKebab 						    = Helpers.toKebab
  Vue.prototype.$toTitle                = Helpers.toTitle
	Vue.prototype.$formatTel 					    = Helpers.formatTel
	Vue.prototype.$wrapText						    = Helpers.wrapText
  Vue.prototype.$is                     = Helpers.is
  Vue.prototype.$getFirstName           = Helpers.getFirstName
  Vue.prototype.$craftVueRouteTransform = Helpers.craftVueRouteTransform
  Vue.prototype.$updateCssVars          = Helpers.updateCssVars
}


Helpers.install = install
export default Helpers;

