<template>
  <div id="home" v-if="craftLoaded && settingsLoaded">

    <hero-module :graident="{anim: true}" :subheading="entry.subheading">
      <template v-slot:heading>{{entry.heading1}}<span>{{entry.heading2}}</span></template>
    </hero-module>

    <smart-container :inset="1" :bg="'vl-grey'" :graident="{color: 'green', angle: 24}">
      <feature-carousel :data="featureCarouselData"></feature-carousel>    
    </smart-container>

    <smart-container :inset="1" :graident="{color: 'blue', angle: 205, start: 75}">
      <statement-module :bg="false" :inset="false">
        <template v-slot:statement>{{entry.aboutCopy}}</template>
        <template v-slot:link>
          <router-link :to="convertLink(entry.homeAboutLink)" class="btn">{{entry.homeAboutLinkLabel}}</router-link>
        </template>
      </statement-module>

      <image-feature :image-graident="{color: 'blue', position: 'bl'}" :data="imageFeature"></image-feature>
    </smart-container>

  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import ImageFeature from '../ImageFeature.vue'
import GraidentImage from '../helpers/GraidentImageHelper.vue'
import StatementModule from '../StatementModule.vue'
import FeatureCarousel from '../FeatureCarousel.vue'
import gql from 'graphql-tag'

  export default {
  components: { HeroModule, SmartContainer, ImageFeature, GraidentImage, StatementModule, FeatureCarousel },
    data() {
      return {
        entry: Object,
        featureCarouselData: {
          title: 'INNOVATION<br/>IN ACTION',
          slides: []
        },
        imageFeature: {
          mode: '',
          image: null,
          videoURL: null,
          features: []
        }
      }
    },
    computed: {
    },
    apollo: {
      page: {
        query: gql`query{
          page: entry(section: "home") {
            id title slug 
            ...on home_home_Entry {
              seoTitle seoDescription
              heading1: heroHeading1 
              heading2: heroHeading2 
              subheading: heroBlurb 
              featuredClinicalExpertise {
                ...on newsArticles_default_Entry {
                 id title slug date: postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
                  category: category {
                    title
                  }
                  heading teaser
                }
              }
              homeAboutLink {
                id title slug 
              }
              homeAboutLinkLabel
              aboutCopy
              imageVideoBlockMode
              image{
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              videoURL
              features: introLinks{
                ...on introLinks_introContent_BlockType{
                  heading content: introContent linkLabel
                  link: introLink {
                    ...on EntryInterface{
                      typeHandle slug 
                    }
                  }
                }
              }
            } 
          }     
        }
        `,
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              let featureCarouselData = this.featureCarouselData
              data.page.featuredClinicalExpertise.forEach(function (article, index) {
                featureCarouselData.slides.push(article)
              });
              let imageFeature = this.imageFeature
              imageFeature.mode = data.page.imageVideoBlockMode
              imageFeature.image = data.page.image
              imageFeature.videoURL = data.page.videoURL
              imageFeature.features = data.page.features
              console.log(data.page)
              console.log(featureCarouselData)
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 500}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    methods: {
      convertLink(link) {
        let routeName = this.$craftVueRouteTransform(link[0].__typename,link[0].slug)
        let result = {
            name: link[0].slug
          }
        if (!routeName){
          return result // 1:1 route name : slug
        }
        else{
          result.name = routeName
          if (link[0].slug) {
            result.params = {
              slug: link[0].slug
            }
          }
          //console.log(result)
        }
        return result
      }
    }
  }
</script>