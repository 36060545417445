<template>
  <div class="graident" 
       :style="graidentStyles" 
       :class="{active:active, 'is-anim': isAnim}" 
       v-observe-visibility="{callback: visibilityChanged, intersection: {rootMargin:'-25%'}}">
    <template v-if="isAnim">
      <span></span>
      <span></span>
      <span></span>
    </template>
  </div>
</template>

<script>

  export default {
    components : {  },
    props: {
      graident: {
        type: [Object, Boolean],
        default: false,
      },
    },
    data() {
      return {
        active: false,
        warnings: false
      }
    },
    computed: {
      isAnim() {
        return this.graident.anim === true
      },
      graidentStyles() {
        if(this.graident) {
          let color
          let angle
          let opacity
          let start

          if (this.graident.opacity !== undefined) {
            opacity = this.graident.opacity
          } else {
            opacity = 0.5
            if(this.warnings) {
              console.warn('GraidentHelper.vue: graident opacity not set, defaulting to 0.5')
            }
          }

          let blue = `rgb(0,156,222,${opacity})`
          let green = `rgb(164,199,76,${opacity})`
          let purple = `rgb(130,108,241,${opacity})`

          //randomly pick color
          let colors = [blue, green, purple]
          color = colors[Math.floor(Math.random() * colors.length)]

          // randomise delay
          let delay = Math.floor(Math.random() * 2500) + 1

          // let default = `rgb(255,105,180,${opacity})`

          // switch(this.graident.color) {
          //   case 'blue':
          //     color = `rgb(0,156,222,${opacity})`
          //     break;
          //   case 'green':
          //     color = `rgb(164,199,76,${opacity})`
          //     break;
          //   case 'purple':
          //     color = `rgb(130,108,241,${opacity})`
          //     break;
          //   default:
          //     color = `rgb(255,105,180,${opacity})`
          //     if(this.warnings) {
          //       console.warn('GraidentHelper.vue: graident color not set, defaulting to hotpink')
          //     }
          // }

          switch (this.graident.position) {
            case 'tl':
              angle = 315
              break;
            case 'tr':
              angle = 45
              break;
            case 'bl':
              angle = 225
              break;
            case 'br':
              angle = 135
              break;
            case 'left':
              angle = 270
              break;
            case 'right':
              angle = 90
              break;
            default:
              if (this.graident.angle !== undefined) {
                angle = this.graident.angle
              } else {
                angle = 90
                if (this.warnings) {
                  console.warn('GraidentHelper.vue: angle not set, defaulting to 90°')
                }
              }
          }

          if (this.graident.start !== undefined) {
            start = this.graident.start
          } else {
            start = 50
            if(this.warnings) {
              console.warn('GraidentHelper.vue: graident start not set, defaulting to 50%')
            }
          }

          return { background: `linear-gradient(${angle}deg, transparent ${start}%, ${color} 100%)`, animationDelay: `${delay}ms`}
        }
      }
    },
    methods: {
      visibilityChanged(isVisible, entry) {
        if(!this.active) {
          this.active = isVisible
        }
      }
    }
  }
</script>