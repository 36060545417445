<template>
  <div id="news-article" v-if="craftLoaded && settingsLoaded">
    <hero-module>
      <template v-slot:heading>{{entry.title}}</template>
    </hero-module>

    <smart-container v-if="entry.heroImage.length" :inset="1" class="hero-image">
      <div class="img-wrapper is-cover one-two">
        <craft-image-helper :src="entry.heroImage"></craft-image-helper>
      </div>
    </smart-container>

    <smart-container :inset="2">
      <div class="article-wrapper" :class="{ 'no-image': !entry.heroImage.length }">
        <div class="detail">
          Published : {{entry.postDate}}<br/>
          <template v-if="entry.author">{{entry.author}}</template>
        </div>
        <div class="introduction">{{entry.intro}}</div>
        <div class="wysiwyg" v-html="entry.mainContent"></div>
        <router-link :to="{name: 'news'}" class="back">BACK TO NEWS</router-link>
      </div>
    </smart-container>
  </div>
</template>

<script>
import HeroModule from '../HeroModule.vue'
import SmartContainer from '../SmartContainer.vue'
import gql from 'graphql-tag'

  export default {
    components : { HeroModule, SmartContainer },
    data() {
      return {
        entry: Object,
      }
    },
    apollo: {
      page: {
        query: gql` query variables($slug: [String!]){
          page: entry(section: "newsArticles",slug: $slug) {
            id title slug postDate @formatDateTime (format: "F d, Y", timezone: "Pacific/Auckland")
            ...on newsArticles_default_Entry {
              seoTitle seoDescription author: articleAuthor
              heroImage {
                url
                title
                width
                height
                focalPoint
                mimeType
                s400: url @transform(width: 400)
                s800: url @transform(width: 800)
                s1200: url @transform(width: 1200)
                s1600: url @transform(width: 1600)
                s2000: url @transform(width: 2000)
                s2400: url @transform(width: 2400)
                s2800: url @transform(width: 2800)
                s3200: url @transform(width: 3200)
              }
              intro: newsIntro mainContent
            }
          }     
        }
        `,
        variables() {      
          return {slug: this.$route.params.slug}
        },
        result ({ data, loading, networkStatus }) {
          if (data !== undefined){
            if (data.page !== null){
              this.entry = data.page
              if (data.page.seoTitle !== null)
                this.$store.commit('setTitle',data.page.seoTitle)
              else
                this.$store.commit('setTitle',data.page.title)
              if (data.page.seoDescription !== null)
                this.$store.commit('setDescription',data.page.seoDescription)
              else
                this.$store.commit('setDescription','')
              console.log(data.page)
              this.$store.commit('setCraftPageData', data.page)
              this.$store.commit('setCraftLoaded', true)
            }
            else{
              this.$router.push({ name: 'error', params: {status: 404}})
            }
          }
          else{
            this.$router.push({ name: 'error', params: {status: 500}})
          }
        },
      }
    },
    computed: {
    },
    methods: {
    }
  }
</script>