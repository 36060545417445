<template>
  <div class="smart-container" :class="bgClasses">
    <graident-helper v-if="graident" :graident="graident"></graident-helper>
    <div class="container" :class="insetClasses">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import GraidentHelper from './helpers/GraidentHelper.vue'

  export default {
    components : { GraidentHelper },
    props: {
      inset: {
        type: [Number, Boolean],
        default: false
      },
      graident: {
        type: [Object, Boolean],
        default: false,
      },
      bg: {
        type: [String, Boolean],
        default: false
      }
    },
    data() {
      return {
      }
    },
    computed: {
      insetClasses() {
        return (this.inset) ? 'inset-'+ this.inset : false
      },
      bgClasses() {
        return (this.bg) ? 'bg-'+ this.bg : false
      }
    },
    methods: {
    }
  }
</script>