<template>
  <div class="image-feature">
    <graident-image v-if="data.mode === 'image'" :src="data.image" :ratio="'four-three'" :graident="imageGraident"></graident-image>
    <video-helper v-else :src="data.videoURL"></video-helper>
    <div class="features" :class="count">
      <ul v-for="feature in data.features" :class="featureClasses(feature)">
        <li class="teaser-wrapper">
          <ul>
            <li>{{feature.heading}}</li>
            <!-- <li v-if="feature.teaser">{{feature.teaser}}</li> -->
            <li v-if="feature.content" class="wysiwyg" v-html="feature.content"></li>
          </ul>
        </li>
        <li v-if="feature.link.length">
          <router-link :to="featureLink(feature)" class="btn">{{feature.linkLabel}}</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import GraidentImage from './helpers/GraidentImageHelper.vue'
import VideoHelper from './helpers/VideoHelper.vue'

  export default {
    components: { GraidentImage, VideoHelper },
    props: {
      // image: {
      //   type: [String, Boolean],
      //   default: false
      // },
      imageGraident: {
        type: [Object, Boolean],
        default: false
      },
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
      }
    },
    computed: {
      count() {
        return 'count-' + this.data.features.length
      },
    },
    methods: {
      featureClasses(feature) {
        let result = []
        if (feature.link.length) {
          result.push('has-link')
        }
         // show all content without fade out if feature.content is provided
         // this could be hardcoded for teaser content if they dont like the fade out effect
         // note that things could get pretty long on SM breakpoint
        if (feature.content) { 
          result.push('show-all')
        }
        return result
      },
      featureLink(feature) {
        let routeName = this.$craftVueRouteTransform(feature.link[0].__typename,feature.link[0].slug)
        let result = {
            name: feature.link[0].slug
          }
        if (!routeName){
          return result // 1:1 route name : slug
        }
        else{
          result.name = routeName
          if (feature.link[0].slug) {
            result.params = {
              slug: feature.link[0].slug
            }
          }
          //console.log(result)
        }
        return result
      }
    },
  }
</script>