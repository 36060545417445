<template>
  <main>
    <app-menu v-if="!errorPage"></app-menu>
    <div class="content">
      <router-view :key="this.route"></router-view>
    </div>
    <app-footer v-if="!errorPage"></app-footer>
  </main>
</template>

<script>
  import AppMenu from '~/components/AppMenu.vue'
  import AppFooter from '~/components/AppFooter.vue'

  export default {
    components: { AppMenu, AppFooter },
    data() {
      return {
      }
    },
    computed: {
      isXs() {
        return this.$store.state.breakpoint === 'xs'
      },
      route() {
        return this.$route.name
      },
      errorPage(){
        return this.route === 'error'
      },
    },
  }
</script>
