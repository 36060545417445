<template>
  <smart-container id="submenu" ref="submenu" :class="{'is-pinned' : pinned}">
    <ul>
      <template v-for="(child, index) in children">
        <li @click="scrollTo(child)" :class="{active : child == activeChild}">{{$toTitle(child)}}</li>
        <li v-if="index !== children.length -1" class="spacer">|</li>
      </template>
    </ul>
  </smart-container>
</template>

<script>
import SmartContainer from './SmartContainer.vue'
import { EventBus } from '/event-bus.js';


export default {
  components: { SmartContainer },
  props: {
    children: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      pinned: false,
      activeChild: null,
    }
  },
  computed: {
    breakpoint() {
      return this.$store.state.breakpoint
    }
  },
  methods: {
    scrollTo(target) {
      let offset

      if(this.breakpoint === 'xs') {
        offset = 50 + 40 // menuHeight + submenuHeight
      } else if (this.breakpoint === 'sm') {
        offset = 50 + 50 // menuHeight + submenuHeight
      } else if(this.breakpoint === 'md') {
        offset = 65 + 50 // menuHeight + submenuHeight
      } else {
        offset = 80 + 50 - 10 // menuHeight + submenuHeight - 10px offset
      }

      this.$scrollTo('#' + target, 500, {
        easing: [0.45, 0, 0.55, 1],
        offset: -offset
      });
    },
    checkSubmenu() {
      this.pinned = false
      if (this.breakpoint !== 'xs' && this.breakpoint !== 'sm' && this.breakpoint !== 'md') {
        if (this.$refs['submenu'].$el.getBoundingClientRect().top === 70) { // see scss
          this.pinned = true
        }
      }
    }
  },
  created() {
    window.addEventListener('scroll', this.checkSubmenu);
  },
  mounted() {
    EventBus.$on('updateSubmenu', (emit) => {
      this.activeChild = emit
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkSubmenu);
  },
}
</script>