<template>
  <div class="share">
    <span><slot></slot></span>
    <a :href="linkedIn" target="_blank">
      <img :src="$getImageSrc('linkedin')">
    </a>
    <a :href="facebook" target="_blank">
      <img :src="$getImageSrc('facebook')">
    </a>
    <a :href="twitter" target="_blank">
      <img :src="$getImageSrc('twitter')">
    </a>
    <a :href="email">
      <img :src="$getImageSrc('email')">
    </a>
  </div>
</template>

<script>

  export default {
    props: {
      title: {
        type: String,
        required: true
      }
    },
    data() {
      return {
      }
    },
    computed: {
      fullPath() {
        return window.location.href
      },
      linkedIn() {
        return `https://www.linkedin.com/sharing/share-offsite/?url=${this.fullPath}`
      },
      facebook() {
        return `https://www.facebook.com/sharer/sharer.php?u=${this.fullPath}`
      },
      twitter() {
        return `https://twitter.com/intent/tweet?text=${this.fullPath}`
      },
      email() {
        return `mailto:?subject=RHCNZ : ${this.title}&body=${this.fullPath}`
      }
    }
  }
</script>