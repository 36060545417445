<template>
  <div class="teaser-carousel">
    <carousel-helper :class="{inset: inset}" :options="carouselOptions" :custom-pagination="false" :custom-nav="true">
      <swiper-slide v-for="(slide, index) in data.slides" class="slide" :key="index">
        <div class="inner" :class="{'no-image': !slide.image.length}">
          <ul>
            <!-- combo mode while some pages are craft and some are inline data -->
            <li v-if="slide.id && slide.category">{{slide.category[0].title || title}}</li>
            <li v-else>{{slide.category || title}}</li>
            <li class="teaser-wrapper">
              <ul>
                <li>{{slide.heading}}</li>
                <li>{{slide.teaser}}</li>
              </ul>
            </li>
            <li>
              <router-link :to="{name: link.name, params: { slug: slide.slug}}" class="btn">{{link.title}}</router-link>
            </li>
          </ul>
          <div v-if="slide.image.length" class="image-wrapper is-cover three-two">
              <!-- combo mode while some pages are craft and some are inline data -->
              <craft-image-helper v-if="slide.id" :src="slide.image"></craft-image-helper>
              <img v-else :src="$getImageSrc(slide.image)">
          </div>
        </div>
      </swiper-slide>
    </carousel-helper>
  </div>
</template>

<script>
import CarouselHelper from "./helpers/CarouselHelper.vue";
import { SwiperSlide } from 'vue-awesome-swiper'

  export default {
    components: { CarouselHelper, SwiperSlide },
    props: {
      data: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        title: null,
        link: {
          name: null,
          title: null
        },
        carouselOptions: {
          centeredSlides: true,
          spaceBetween: 60,
          loop: true,
          speed: 1000,
          autoHeight: true,
          allowTouchMove: false,
          preventClicks: false,
          preventClicksPropagation: false
          // slidesPerView: 'auto',
          // autoplay: 5000,
        }
      }
    },
    computed: {
      mode() {
        return this.data.mode
      },
      inset() {
        return this.$store.state.breakpoint !== 'xs' && this.$store.state.breakpoint !== 'sm';
      }
    },
    methods: {
    },
    mounted() {
      if(this.mode === 'news') {
        this.title = 'News'
        this.link.name = 'news-article'
        this.link.title = 'Read More'
      } else if(this.mode === 'jobs') {
        this.title = 'Job Listing'
        this.link.name = 'job-listing'
        this.link.title = "View Listing"
      }
    }
  }
</script>