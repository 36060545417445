<template>
  <div class="image-video-block">
    <div v-if="hasImage" class="img-wrapper is-cover one-two">
      <craft-image-helper :src="data.src"></craft-image-helper>
    </div>
    <video-helper v-if="hasVideo" :src="data.src"></video-helper>
    <div v-if="data.copy" class="wysiwyg" v-html="data.copy"></div>
  </div>
</template>

<script>
  import VideoHelper from './helpers/VideoHelper.vue'

  export default {
    components: { VideoHelper },
    props: {
      data: {
        type: [Object],
      },
    },
    data() {
      return {

      }
    },
    computed: {
      hasVideo() {
        return this.data.mode === 'video'
      }, 
      hasImage() {
       return this.data.mode === 'image'
      }
    },
    methods: {
    },
  }
</script>